import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ApplicationRole, CounterpartyListViewModel, CounterpartyStatus } from 'schema';
import { Colorize, IconSprite, Role } from 'components';
import { themeOrange as theme } from 'theme';
import { CounterpartiesDataReturn } from './types';
import { useLanguage } from '../utils/useLanguage';
import { TableSortLabel } from 'components/data-grid/TableSortLabel';
import { RatingLabel } from '../calculator2/CalculationForm/RatingLabel';

const useStyles = makeStyles((theme) =>
  createStyles({
    abbreviatedName: {
      minWidth: '280px',
    },
    okved: {
      minWidth: '120px',
    },
    list: {
      marginTop: theme.spacing(0.2),
      marginBottom: theme.spacing(0.2),
    },
    change: {
      width: '41px',
    },
    missing: {
      width: '88px',
    },
    calculation: {
      width: '80px',
    },
    tier: {
      width: '70px',
    },
    status: {
      width: '70px',
    },
    tin: {
      width: '100px',
      [theme.breakpoints.up('md')]: {
        width: '140px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '200px',
      },
    },

    userType: {
      width: '140px',
      [theme.breakpoints.up('md')]: {
        width: '212px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '372px',
      },
    },
    rating: {
      '&&': {
        paddingTop: 0,
        paddingBottom: 0,
        verticalAlign: 'middle',
      },
    },
  })
);

const useStatusText = (status: CounterpartyStatus) => {
  const { t } = useTranslation();

  switch (status) {
    case CounterpartyStatus.Active:
      return t('CounterpartyStatus.Active');
    case CounterpartyStatus.NotActive:
      return t('CounterpartyStatus.NotActive');
    case CounterpartyStatus.Bankruptcy:
      return t('CounterpartyStatus.Bankruptcy');
    case CounterpartyStatus.Liquidation:
      return t('CounterpartyStatus.Liquidation');
    case CounterpartyStatus.Reorganization:
      return t('CounterpartyStatus.Reorganization');
    default:
      return t('CounterpartyStatus.Uknown');
  }
};

const TableItem = (props: CounterpartyListViewModel) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = useLanguage();
  const {
    name,
    transliteratedName,
    inn,
    isDealer,
    isInsuranceCompany,
    isLessee,
    isLessor,
    missingsCount,
    quotasCount,
    tier,
    principalActivity,
    status,
    ratingText,
    isSupportSupplier,
    isVendorSupplier,
  } = props;

  const roles = [
    isDealer ? t('Dealer') : false,
    isInsuranceCompany ? t('InsuranceCompany') : false,
    isLessee ? t('Lessee') : false,
    isLessor ? t('Lessor') : false,
  ];
  const counterpartyUrl = `/counterparties/${inn}`;

  const statusText = useStatusText(status);

  return (
    <TableRow>
      <TableCell size="medium">
        <Link to={counterpartyUrl}>{lang === 'ru' ? name : transliteratedName}</Link>
      </TableCell>
      <TableCell size="medium">{principalActivity?.code}</TableCell>
      <TableCell size="medium">
        <ul>
          {roles
            .filter((t) => t !== false)
            .map((role, i) => (
              <li className={classes.list} key={i}>
                {role}
              </li>
            ))}
        </ul>
      </TableCell>
      <TableCell size="medium">
        {isSupportSupplier && t('IsSupportSupplier')}
        <br />
        {isVendorSupplier && t('IsVendorSupplier')}
      </TableCell>
      <TableCell size="medium">{inn}</TableCell>
      <TableCell size="medium" align="center">
        <Colorize value={missingsCount} />
      </TableCell>
      <TableCell size="medium" align="center">
        <Link to={`/quotas?inn=${inn}#all`}>{quotasCount}</Link>
      </TableCell>
      <TableCell size="medium">{tier.toUpperCase()}</TableCell>
      <TableCell size="medium" className={classes.rating}>
        <RatingLabel
          ratingGrade={ratingText ? parseInt(ratingText.substring(1), 10) : undefined}
          inn={inn}
        />
      </TableCell>
      <TableCell size="medium">{statusText}</TableCell>
      <TableCell size="medium">
        <Role
          role={[
            ApplicationRole.Admin,
            ApplicationRole.SalesManager,
            ApplicationRole.SalesSupport,
            ApplicationRole.SuperSalesManager,
            ApplicationRole.RiskManager,
          ]}
        >
          <Link to={counterpartyUrl}>
            <IconSprite
              width="14px"
              color={theme.palette.text.primary}
              hoverColor={theme.palette.primary.main}
              icon="edit"
            />
          </Link>
        </Role>
      </TableCell>
    </TableRow>
  );
};

export type CounterpartiesTableProps = Pick<CounterpartiesDataReturn, 'rows' | 'sorting'>;

export const CounterpartiesTable = (props: CounterpartiesTableProps) => {
  const { rows, sorting } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="medium" className={classes.abbreviatedName}>
            {t('AbbreviatedName')}
          </TableCell>
          <TableCell size="medium" className={classes.okved}>
            {t('Specialization code')}
          </TableCell>
          <TableCell className={classes.userType} size="medium">
            {t('UserType')}
          </TableCell>
          <TableCell className={classes.userType} size="medium">
            {t('CounterpartyType')}
          </TableCell>
          <TableCell className={classes.tin} size="medium">
            {t('Inn')}
          </TableCell>
          <TableCell className={classes.missing} size="medium">
            <TableSortLabel columnName="missingsCount" sorting={sorting}>
              {t('Missing')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={classes.calculation} size="medium">
            <TableSortLabel columnName="quotasCount" sorting={sorting}>
              {t('Calculation_plural')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={classes.tier} size="medium">
            <TableSortLabel columnName="tier" sorting={sorting}>
              {t('Tier')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={classes.tier} size="medium">
            {t('Rating')}
          </TableCell>
          <TableCell className={classes.status} size="medium">
            <TableSortLabel columnName="status" sorting={sorting}>
              {t('Organization status')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={classes.change} size="medium" />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row: CounterpartyListViewModel) => (
          <TableItem key={row.id} {...row} />
        ))}
      </TableBody>
    </Table>
  );
};
