import { useEffect, useRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import { useTabs, Tabs, TabPanel, ShipmentForm } from 'components';
import { useQuotaCalculationForm } from './useQuotaCalculationForm';
import { CalculationForm } from '../CalculationForm';
import { useContext } from 'react';
import { CurrencyRatesContext } from '../CurrencyRatesContext';
import { ContractForm } from '../ContractForm';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { useErrorContext } from 'components/tasks';
import { IssueSource } from 'schema/serverTypes';
import { QuotaProps } from '../QuotaDataGrid/types';

type CalculatorFormProps = QuotaProps & {
  shipmentItemId?: number;
};

const CalculatorForm = ({ quota }: CalculatorFormProps) => {
  const { onSubmit, initialValues, error, isLoading, data, resultRef } =
    useQuotaCalculationForm(quota);

  return (
    <CalculationForm
      ref={resultRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      error={error}
      isLoading={isLoading}
      isLocked={quota.isLocked}
      data={data}
      copyEnabled={true}
      quotaId={quota.quotaId}
      isTask={true}
    />
  );
};

export const QuotaCalculatorFormTask = ({ quota, shipmentItemId }: CalculatorFormProps) => {
  const { t } = useTranslation();
  const tabsProps = useTabs(
    [t('Calculation') as string, t('Contract') as string, t('Shipment') as string],
    1
  );
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;

  const { quotaId } = quota;
  const { setSaleCurrency, setLeaseCurrency } = useContext(CurrencyRatesContext);

  const title = t('Calculation');

  useErrorContext({
    title,
    source: IssueSource.quota,
    quotaId: quotaId,
  });

  const currencySale = quota?.input.currencySale;
  const currencyLease = quota?.input.currencyLease;
  const currenciesUpdated = useRef<boolean>(false);

  useEffect(() => {
    if (currenciesUpdated.current) {
      return;
    }
    if (currencySale && currencyLease) {
      currenciesUpdated.current = true;
      setSaleCurrency(currencySale);
      setLeaseCurrency(currencyLease);
    }
  }, [currencySale, currencyLease, setSaleCurrency, setLeaseCurrency]);

  return (
    <Grid container direction="column">
      <Box mb={2.5} display="flex" justifyContent="space-between" alignItems="center">
        <Tabs {...tabsProps} value={tabIndex} onChangeTab={onChangeTab} />
      </Box>
      <Grid item>
        <SwipeableViews
          containerStyle={{
            transition: 'transform 0.6s ease-out 0s',
          }}
          springConfig={{
            duration: '0.6s',
            easeFunction: 'transform 0.6s ease-out 0s',
            delay: '0s',
          }}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabIndex}
          onChangeIndex={onChangeTabIndex}
        >
          <TabPanel value={tabIndex} index={0} dir={theme.direction}>
            {tabIndex === 0 && <CalculatorForm quota={quota} />}
          </TabPanel>
          <TabPanel value={tabIndex} index={1} dir={theme.direction}>
            {tabIndex === 1 && <ContractForm quota={quota} isTask />}
          </TabPanel>
          <TabPanel value={tabIndex} index={2} dir={theme.direction}>
            {tabIndex === 2 && (
              <ShipmentForm quota={quota} isTask shipmentItemId={shipmentItemId} />
            )}
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};
