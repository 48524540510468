import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 24,
      height: 24,
      fontSize: 10,
      borderRadius: '50%',
      border: '1px solid',
      marginLeft: theme.spacing(2),
    },
    rating: {
      '&&&': {
        borderBottom: '1px solid',
        textDecoration: 'none',
        borderColor: (props: any) =>
          props.ratingGrade === 20
            ? theme.palette.error.main
            : props.ratingGrade === 19
            ? theme.palette.primary.main
            : props.ratingGrade === 18
            ? theme.palette.warning.light
            : props.ratingGrade === 17
            ? theme.palette.success.dark
            : theme.palette.success.main,
        color: (props: any) =>
          props.ratingGrade === 20
            ? theme.palette.error.main
            : props.ratingGrade === 19
            ? theme.palette.primary.main
            : props.ratingGrade === 18
            ? theme.palette.warning.light
            : props.ratingGrade === 17
            ? theme.palette.success.dark
            : theme.palette.success.main,
      },
    },
    empty: {
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.common.white,
    },
    none: {
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.common.white,
      position: 'relative',
      '&:after': {
        content: "''",
        position: 'absolute',
        display: 'block',
        width: 24,
        height: 1,
        backgroundColor: theme.palette.common.white,
        transform: 'rotate(-45deg)',
      },
    },
  })
);

type RatingLabelProps = {
  inn?: string;
  ratingGrade?: number;
};
export const RatingLabel = (props: RatingLabelProps) => {
  const classes = useStyles(props);

  const { ratingGrade, inn } = props;

  return ratingGrade ? (
    <a
      href={`/counterparties/${inn}#ratings`}
      target="_blank"
      rel="noreferrer"
      className={clsx(classes.root, classes.rating)}
    >
      R{ratingGrade}
    </a>
  ) : (
    <div className={clsx(classes.root, classes.empty)}>R</div>
  );
};
