import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '../Grid';
import { MenuItem, Typography } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { Input, Select, Switch } from '../form';
import { useForm } from 'react-hook-form';
import { Button } from '../Button';
import { CreateFormFieldValues } from './types';
import { ApplicationRole, TemplateKind, TemplateViewModel } from 'schema/serverTypes';
import React, { useEffect, useMemo } from 'react';
import { useTemplatesBackendQuery, useTemplatesFileUploadMutation } from 'services/api';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { FileUpload } from './FileUpload';
import { UserSelectField } from './UserSelectField';
import { LessorSelectField } from './LessorSelectField';
import { DealerSelectField } from './DealerSelectField';
import { getFormData } from './getFormData';
import { useTemplateKind } from './useTemplateKind';
import { IconBackTo } from '../icons';
import { Role } from '../authentication';
import { useToast } from '../Toast';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    head: {
      color: theme.palette.secondary.main,
    },
    wrapper: {
      width: '100%',
    },
    span: {
      marginLeft: theme.spacing(1.6),
    },
  })
);

export const TemplateEditForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { state } = useLocation<TemplateViewModel | undefined>();
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { control, handleSubmit, reset, setValue, watch } = useForm<CreateFormFieldValues>({
    mode: 'onBlur',
    defaultValues: state ?? {
      title: '',
      description: '',
      kind: TemplateKind.Calculation,
      file: null,
      fileName: '',
      isAvailabelForAllUsers: false,
      users: [],
      dealers: [],
      lessor: null,
    },
  });

  const isAvailabelForAllUsers = watch('isAvailabelForAllUsers');

  const { data: template } = useTemplatesBackendQuery<TemplateViewModel>({
    relativeUrl: `${id}`,
    queryKey: ['templates', id],
    options: {
      enabled: state === undefined,
      refetchOnMount: true,
    },
  });

  const toast = useToast();
  const { push } = useHistory();
  const { mutateAsync } = useTemplatesFileUploadMutation<CreateFormFieldValues, TemplateViewModel>(
    `${id}`,
    getFormData,
    {
      method: 'PUT',
      onSuccess: () => {
        toast(t('TemplateEditSuccessMessage'), 'success');
        push(`/templates`, template);
      },
      onError: () => {
        toast(t('ErrorMessage'), 'error');
      },
    }
  );

  const onSubmit = useMemo(() => {
    const submit = async (values: CreateFormFieldValues) => {
      await mutateAsync(values);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const { isContractKind } = useTemplateKind(control);

  useEffect(() => {
    if (template) {
      const { lessor = null, ...rest } = template;
      reset({ lessor, ...rest });
    }
  }, [template, reset]);

  return (
    <div className={classes.wrapper}>
      <div>
        <Button variant="iconButton" endIcon={<IconBackTo />} to="/templates" />
        <span className={classes.span}>{t('Edit template')}</span>
      </div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="subtitle1">{t('Edit template')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form className={classes.root}>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid item md={12} xs={24}>
                <Select label={`${t('Template type')}`} name="kind" control={control}>
                  <MenuItem value={TemplateKind.Calculation}>{t('Calculation')}</MenuItem>
                  <MenuItem value={TemplateKind.CalculationDetailed}>
                    {t('Calculation detailed')}
                  </MenuItem>
                  <MenuItem value={TemplateKind.External1C}>{t('Exchange with 1C')}</MenuItem>
                  <MenuItem value={TemplateKind.Contract}>{t('Contract')}</MenuItem>
                  <MenuItem value={TemplateKind.Shipment}>{t('Shipment')}</MenuItem>
                  <MenuItem value={TemplateKind.Pipeline}>{t('Pipeline')}</MenuItem>
                  <MenuItem value={TemplateKind.Quotas}>{t('Quotas')}</MenuItem>
                </Select>
              </Grid>
              <Grid item md={12} xs={24}>
                <FileUpload
                  name={'fileName'}
                  control={control}
                  setValue={setValue}
                  required={true}
                  downloadUrl={template?.downloadUrl}
                />
              </Grid>
              <Grid item xs={24}>
                <Input
                  label={`${t('Template name')}`}
                  name="title"
                  control={control}
                  required={true}
                />
              </Grid>
              <Grid item xs={24}>
                <Input
                  multiline={true}
                  label={`${t('Description')}`}
                  name="description"
                  control={control}
                />
              </Grid>
              {!isContractKind && (
                <>
                  <Grid item xs={'auto'}>
                    <Switch
                      label={'Доступен\u00a0всем'}
                      name="isAvailabelForAllUsers"
                      control={control}
                      labelOn={''}
                      labelOff={''}
                    />
                  </Grid>
                  <Grid item xs={23}>
                    <UserSelectField
                      control={control}
                      required={!isContractKind}
                      setValue={setValue}
                      disabled={isAvailabelForAllUsers}
                    />
                  </Grid>
                </>
              )}
              {isContractKind && (
                <>
                  <Grid item md={12} xs={24}>
                    <LessorSelectField control={control} required={isContractKind} />
                  </Grid>
                  <Grid item md={12} xs={24}>
                    <DealerSelectField control={control} required={isContractKind} />
                  </Grid>
                </>
              )}
              <Grid item xs={24}>
                <Role
                  role={[
                    ApplicationRole.Admin,
                    ApplicationRole.SuperSalesManager,
                    ApplicationRole.RiskManager,
                  ]}
                >
                  <Button variant="contained" onClick={onSubmit}>
                    {t('Save')}
                  </Button>
                </Role>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
