import { useTranslation } from 'react-i18next';
import { createStyles, InputAdornment, Box, makeStyles, TextField, Theme } from '@material-ui/core';
import { CounterpartyOption } from 'schema/serverTypes';
import { useDebounce } from 'use-debounce';
import { useCalculationFormContext } from 'components/calculator2/CalculationForm/types';
import { FieldError, useController, useWatch } from 'react-hook-form';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/useAutocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDictionaryBackendQuery } from 'services/api/useQuotasBackend';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { themeOrange as theme } from 'theme';
import { IconSprite } from 'components/icons/IconSprite';
import { RatingLabel } from './RatingLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 11px) scale(1)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(9px, -6px) scale(0.75)',
        },
      },
    },
    warningIcon: {
      marginTop: 2,
      cursor: 'pointer',
      '&$warningIcon svg': {
        fill: theme.palette.darkAttention.main,
        cursor: 'pointer',
        pointerEvents: 'auto',
      },
    },
  })
);

const getOptionLabel = (option: CounterpartyOption | string) => {
  if (typeof option === 'string') {
    return option;
  }

  const { id = 0 } = option;

  if (id === 0) {
    return option.name;
  }

  return `${option.inn} (${option.name})`;
};

export type ModelAutocompleteProps = {};

const useLesseeQuery = ({ name = '', inn = '' }: { name?: string; inn?: string }) => {
  const relativeUrl = useMemo(() => {
    const searchParams = new URLSearchParams();

    searchParams.set('type', 'lessee');

    if (inn !== '') {
      searchParams.set('inn', inn);
    }
    if (name !== '') {
      searchParams.set('name', name);
    }
    return `counterparties?${searchParams}`;
  }, [name, inn]);

  return useDictionaryBackendQuery<CounterpartyOption[]>(relativeUrl);
};

export const LessseeAutocomplete = (props: ModelAutocompleteProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { control, setValue } = useCalculationFormContext();

  const {
    field: { onChange, ...rest },
    fieldState: { error },
  } = useController({
    control,
    name: 'lessee',
  });

  const resetName = useCallback(
    (value: string | CounterpartyOption | null = null) => {
      if (value === null) {
        setValue('lesseeName', undefined, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      } else if (typeof value === 'string') {
        setValue('lesseeName', value, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      } else {
        setValue('lesseeName', value.name, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      }
    },
    [setValue]
  );

  const resetInn = useCallback(
    (value: string | CounterpartyOption | null = null) => {
      if (value === null) {
        setValue('lessee', undefined, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      } else if (typeof value === 'string') {
        setValue('lessee', value, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
      } else {
        const { id = 0 } = value;
        setValue('lessee', id === 0 ? undefined : value.inn, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      }
    },
    [setValue]
  );

  const name = useWatch({ control, name: 'lesseeName', exact: true });
  const inn = useWatch({ control, name: 'lessee', exact: true });

  const [inputValue, setInputValue] = useState(name && inn ? `${inn} (${name})` : name ? name : '');

  const [input] = useDebounce(inputValue, 500);
  const { data: options = [], refetch } = useLesseeQuery({ name: input, inn });

  const isFirstReset = useRef(name !== undefined && name !== null && name !== '');

  const handleOnInputChange = useCallback(
    (_event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input') {
        setInputValue(value);
        resetInn();
        resetName(value);
      } else if (reason === 'clear') {
        setInputValue('');
        resetInn();
        resetName();
        refetch();
      } else if (reason === 'reset') {
        if (!isFirstReset.current) {
          setInputValue(value);
        } else if (isFirstReset.current) {
          isFirstReset.current = false;
        }
      }
    },
    [setInputValue, resetInn, resetName, refetch]
  );

  const handleOnChange = useCallback(
    (
      _event: ChangeEvent<{}>,
      value: string | CounterpartyOption | null,
      reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<string | CounterpartyOption | null>
    ) => {
      resetName(value);
      resetInn(value);

      if (reason === 'clear') {
        refetch();
      }
    },
    [resetName, resetInn, refetch]
  );

  const helperText = error !== undefined ? (error as FieldError).message : undefined;
  const iconColor = inn ? theme.palette.text.primary : theme.palette.text.primary;
  const iconHoverColor = inn ? theme.palette.primary.main : theme.palette.text.primary;
  const counterpartyLink = inn ? `/counterparties/${inn}` : undefined;
  const ratingGrade = inn ? options.find((t) => t.inn === inn)?.ratingGrade : undefined;

  const label = t('Lessee');

  const icon = (
    <Box p={1} pt={1.4} pr={0}>
      <IconSprite
        icon={'view'}
        width="15px"
        height="10px"
        color={iconColor}
        hoverColor={iconHoverColor}
      />
    </Box>
  );

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box flexGrow={1}>
        <Autocomplete<CounterpartyOption, false, false, true>
          freeSolo
          options={options}
          onChange={handleOnChange}
          onInputChange={handleOnInputChange}
          getOptionLabel={getOptionLabel}
          getOptionSelected={(option, value) => option.inn === value.inn}
          inputValue={inputValue}
          popupIcon={
            <KeyboardArrowDownRoundedIcon {...props} htmlColor={theme.palette.text.primary} />
          }
          renderInput={(params) => (
            <div>
              <TextField
                {...rest}
                {...params}
                className={classes.input}
                label={label}
                error={error !== undefined}
                helperText={helperText}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: counterpartyLink ? (
                    <InputAdornment position="start">
                      <a href={counterpartyLink} target="_blank" rel="noreferrer">
                        {icon}
                      </a>
                    </InputAdornment>
                  ) : null,
                }}
                fullWidth
              />
            </div>
          )}
        />
      </Box>
      <RatingLabel ratingGrade={ratingGrade} inn={inn} />
    </Box>
  );
};
