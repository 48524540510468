import { Grid, createStyles, makeStyles, Theme, Box } from '@material-ui/core';

import { useTabs, Tabs, Button, IconBackTo, useDefaultCalculationValues } from 'components';
import { useTranslation } from 'react-i18next';
import { CalculatorFormValues } from '../CalculationForm/types';
import { useCalculatorForm } from './useCalculatorForm';
import { CalculationForm } from '../CalculationForm/CalculationForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navBottom: {
      marginBottom: '12px',
    },
  })
);

type CreateQuotaFormInnerProps = {
  defaultValues: CalculatorFormValues;
};

const CreateQuotaFormInner = (props: CreateQuotaFormInnerProps) => {
  const { resultRef, ...rest } = useCalculatorForm(props);

  return <CalculationForm ref={resultRef} {...rest} />;
};

export const CreateQuotaForm = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const tabs = [
    {
      name: t('Calculation'),
    },
  ];
  const tabsProps = useTabs(tabs);

  const { tabIndex, onChangeTab } = tabsProps;
  const defaultValues = useDefaultCalculationValues();

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid className={classes.navBottom} item>
          <Button variant="iconButton" endIcon={<IconBackTo />} to="/quotas" />
        </Grid>
      </Grid>
      <Box mb={2.5}>
        <Tabs {...tabsProps} value={tabIndex} onChangeTab={onChangeTab} />
      </Box>
      <Grid item>{defaultValues && <CreateQuotaFormInner defaultValues={defaultValues} />}</Grid>
    </Grid>
  );
};
