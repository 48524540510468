import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import {
  useTabs,
  Tabs,
  TabPanel,
  Button,
  IconBackTo,
  IconSprite,
  RadioOption,
  Radio,
  useModalForm,
  ModalForm,
} from 'components';
import { useQuotaCalculationForm } from './useQuotaCalculationForm';
import { CalculationForm } from '../CalculationForm';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useContext } from 'react';
import { CurrencyRatesContext } from '../CurrencyRatesContext';
import { ContractForm } from '../ContractForm';
import { ShipmentForm } from '../ShipmentForm';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { useErrorContext } from 'components/tasks';
import { IssueSource, QuotaStatus } from 'schema/serverTypes';
import { QuotaProps } from '../QuotaDataGrid/types';
import { FieldValues, useForm } from 'react-hook-form';
import { useSetQuotaStatusMutation } from 'services';
import { ChangeStatusForm } from './ChangeStatusForm';
import { Approval } from '../../calculator2/Approval';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quotaInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1.5),
    },
    span: {
      fontFamily: 'Roboto, san-serif',
      fontSize: 12,
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(1.6),
    },
    button: {
      padding: 0,
    },
    issueInfoWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      background: 'white',
      padding: theme.spacing(0, 2.5),
      borderBottom: '2px solid #E3E6F3',
      marginBottom: theme.spacing(2.5),
      fontWeight: 600,
      fontSize: 16,
      color: theme.palette.primary.main,
      lineHeight: '46px',
      '& a': {
        color: theme.palette.primary.main,
      },
    },
    icon: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1),
      marginBottom: 2,
    },
    contractNumber: {
      fontWeight: 700,
    },
    documentUrl: {
      marginLeft: theme.spacing(1.5),
    },
    quotaStatus: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(1.5),
    },
  })
);

type CalculatorFormProps = QuotaProps & {
  shipmentItemId?: number;
};
type QuotaInfoProps = {
  modelName?: string;
  userName?: string;
  calculationNumber: number;
  status?: QuotaStatus;
};

type IssueInfoProps = {
  contractNumber?: string;
  contractStatus1C?: string;
  issueId?: number;
  documentUrl?: string;
  contractFolderUrl?: string;
};

export const QuotaCalculatorForm = ({ quota, shipmentItemId }: CalculatorFormProps) => {
  const { t } = useTranslation();
  const [isContractDirty, setIsContractDirty] = useState(false);
  const [isShipmentDirty, setIsShipmentDirty] = useState(false);
  const tabs = [
    {
      name: t('Calculation'),
    },
    {
      name: t('Contract'),
      confirmation: isContractDirty,
    },
    {
      name: t('Shipment'),
      confirmation: isShipmentDirty,
    },
    {
      name: t('Approval'),
    },
  ];
  const tabsProps = useTabs(tabs);
  const { tabIndex, onChangeTabIndex, onChangeTab } = tabsProps;
  const {
    quotaId,
    agreement: { model, brand },
    issueId,
    contractNumber,
    contractStatus1C,
    contractFolderUrl,
    documentUrl,
    user: { name: userName },
    status,
  } = quota;
  const { setSaleCurrency, setLeaseCurrency } = useContext(CurrencyRatesContext);

  const title = t('Calculation');

  useErrorContext({
    title,
    source: IssueSource.quota,
    quotaId: quotaId,
  });

  const currencySale = quota?.input.currencySale;
  const currencyLease = quota?.input.currencyLease;
  const currenciesUpdated = useRef<boolean>(false);

  useEffect(() => {
    if (currenciesUpdated.current) {
      return;
    }
    if (currencySale && currencyLease) {
      currenciesUpdated.current = true;
      setSaleCurrency(currencySale);
      setLeaseCurrency(currencyLease);
    }
  }, [currencySale, currencyLease, setSaleCurrency, setLeaseCurrency]);

  return (
    <Grid container direction="column">
      <Grid item>
        <QuotaInfo
          modelName={model || brand}
          userName={userName}
          calculationNumber={quotaId}
          status={status}
        />
        <IssueInfo
          issueId={issueId}
          contractNumber={contractNumber}
          contractStatus1C={contractStatus1C}
          contractFolderUrl={contractFolderUrl}
          documentUrl={documentUrl}
        />
      </Grid>
      <Box mb={2.5} display="flex" justifyContent="space-between" alignItems="center">
        <Tabs {...tabsProps} value={tabIndex} onChangeTab={onChangeTab} />
      </Box>
      <Grid item>
        <SwipeableViews
          containerStyle={{
            transition: 'transform 0.6s ease-out 0s',
          }}
          springConfig={{
            duration: '0.6s',
            easeFunction: 'transform 0.6s ease-out 0s',
            delay: '0s',
          }}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabIndex}
          onChangeIndex={onChangeTabIndex}
        >
          <TabPanel value={tabIndex} index={0} dir={theme.direction}>
            {tabIndex === 0 && <CalculatorForm quota={quota} />}
          </TabPanel>
          <TabPanel value={tabIndex} index={1} dir={theme.direction}>
            {tabIndex === 1 && (
              <ContractForm quota={quota} drawUpEnabled={true} setIsDirty={setIsContractDirty} />
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={2} dir={theme.direction}>
            {tabIndex === 2 && (
              <ShipmentForm
                quota={quota}
                shipmentItemId={shipmentItemId}
                setIsDirty={setIsShipmentDirty}
              />
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={3} dir={theme.direction}>
            {tabIndex === 3 && <Approval quota={quota} />}
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};

export const QuotaInfo = (props: QuotaInfoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    modelName = t('NoModel'),
    userName = '',
    status,
    calculationNumber = t('NoCalculation'),
  } = props;
  const { handleSubmit, control, watch } = useForm<FieldValues>({
    mode: 'onBlur',
    defaultValues: { status },
  });

  const { onOpen, onClose, open } = useModalForm();
  const { mutateAsync } = useSetQuotaStatusMutation();

  const onSubmit = useMemo(() => {
    const submit = async (form: FieldValues) => {
      try {
        if (form.status === QuotaStatus.Active) {
          await mutateAsync({
            quotaId: [calculationNumber],
            ...form,
          });
        } else {
          onOpen();
        }
      } catch (error) {
        console.log(error);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, calculationNumber, onOpen]);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit as any)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onSubmit]);

  return (
    <>
      <div className={classes.quotaInfo}>
        <div>
          <Button variant="iconButton" endIcon={<IconBackTo />} to="/quotas" />
          <span className={classes.span}>
            {t('Calculation')} №{calculationNumber}({userName}) {modelName}
          </span>
        </div>
        <div className={classes.quotaStatus}>
          <form>
            <Radio
              name="status"
              control={control}
              defaultValue={QuotaStatus.Active}
              options={[
                {
                  label: `${t('QuotaStatus.Active')}`,
                  value: QuotaStatus.Active,
                } as RadioOption,
                {
                  label: `${t('QuotaStatus.Lost')}`,
                  value: QuotaStatus.Lost,
                } as RadioOption,
              ]}
            />
          </form>
        </div>
      </div>
      <ModalForm open={open} onClose={onClose}>
        <ChangeStatusForm onSuccess={onClose} quotaId={[calculationNumber]} />
      </ModalForm>
    </>
  );
};

export const IssueInfo = (props: IssueInfoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { contractNumber, contractStatus1C, contractFolderUrl, documentUrl } = props;

  if (!contractNumber) return null;

  return (
    <div className={classes.issueInfoWrapper}>
      <div className={classes.contractNumber}>
        {t('Contract')} №{contractNumber} |{' '}
        {!contractStatus1C ? t('Contract status from 1C absent') : contractStatus1C}
      </div>
      <div>
        {contractFolderUrl && (
          <a href={contractFolderUrl} target="_blank" rel="noreferrer">
            {t('Contract documents')}
            <IconSprite
              className={classes.icon}
              icon={'view'}
              width={'15px'}
              height={'10px'}
              color={theme.palette.text.secondary}
            />
          </a>
        )}
        {documentUrl && (
          <a href={documentUrl} target="_blank" rel="noreferrer" className={classes.documentUrl}>
            {t('Client documents')}
            <IconSprite
              className={classes.icon}
              icon={'view'}
              width={'15px'}
              height={'10px'}
              color={theme.palette.text.secondary}
            />
          </a>
        )}
      </div>
    </div>
  );
};

const CalculatorForm = ({ quota }: CalculatorFormProps) => {
  const { onSubmit, initialValues, error, isLoading, data, resultRef } =
    useQuotaCalculationForm(quota);

  return (
    <CalculationForm
      ref={resultRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      error={error}
      isLoading={isLoading}
      isLocked={quota.isLocked}
      data={data}
      copyEnabled={true}
      drawUpEnabled={!quota.issueId}
      quotaId={quota.quotaId}
      issueId={quota.issueId}
      sendToIsEnabled={!quota.scoringIssueId}
      approvalEnabled={!quota.hasActiveScoringApproval}
    />
  );
};
