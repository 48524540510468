import {
  makeStyles,
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Paper,
  Theme,
  Typography,
  Portal,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { useContractForm } from './useContractForm';
import { DatePicker, Input, Select, Switch, WarningTooltip } from 'components/form';
import { CounterpartyAutocompete } from './CounterpartyAutocompete';
import {
  DepreciationGroup,
  PrepaymentAdvanceOrder,
  Region,
  QuotaCalculationResult,
  TemplateKind,
  ApplicationRole,
  ContractType,
  ContractPaymentConditionType,
} from 'schema/serverTypes';
import { CounterpartyType } from './types';
import { HeadSelect } from './HeadSelect';
import { DepreciationCoefficientSelect } from './DepreciationCoefficientSelect';
import { alpha } from '@material-ui/core/styles';
import { Button, ModalForm, Role, useModalForm } from 'components';
import { PrintableTemplateList } from 'components/templates/PrintableTemplatesList';
import { useWatch } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { OrderPaymentForm } from '../../tasks/OrderPaymentForm';
import { ApplicationRoles } from 'services';
import { NomenclaturesRequestForm } from '../CalculationForm/NomenclaturesRequestForm';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panels: {
      marginBottom: (props: ContractFormProps) => (props.isTask ? '0' : '75px'),
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing(2),
      bottom: 0,
      left: 214,
      position: 'fixed',
      zIndex: 1,
    },
    actionsTask: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: theme.spacing(2),
      bottom: 0,
      position: 'static',
      zIndex: 1,
      marginBottom: theme.spacing(2),
    },
    details: {
      width: '100%',
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
    exceptions: {
      padding: theme.spacing(1),
      backgroundColor: alpha(theme.palette.warning.main, 0.07),
      fontSize: 10,
      lineHeight: 1.2,
      fontWeight: 400,
    },
    accordionDetails: {
      backgroundColor: theme.palette.common.white,
      '& + &': {
        marginTop: 10,
      },
    },
    warningIcon: {
      cursor: 'pointer',
      '&$warningIcon svg': {
        fill: theme.palette.darkAttention.main,
        cursor: 'pointer',
        pointerEvents: 'auto',
      },
    },
  })
);

export type ContractFormProps = {
  drawUpEnabled?: boolean;
  quota: QuotaCalculationResult;
  setIsDirty?: (value: boolean) => void;
  isTask?: boolean;
};

export const ContractForm = (props: ContractFormProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { quota, drawUpEnabled = false, setIsDirty, isTask = false } = props;
  const newNomenclatures = quota.input.nomenclatures.filter((item) => !item.code);

  const {
    onSubmit,
    control,
    isSubmitting,
    isLoading,
    setValue,
    isLocked,
    allowedPaymentTypes,
    canExecuteContract,
    isDirty,
    watch,
  } = useContractForm(quota);

  const {
    onOpen: onOpenOrderPayment,
    onClose: onCloseOrderPayment,
    open: openOrderPayment,
  } = useModalForm();

  const {
    onOpen: onOpenNomenclatures,
    onClose: onCloseNomenclatures,
    open: openNomenclatures,
  } = useModalForm();

  useEffect(() => {
    if (setIsDirty) {
      setIsDirty(isDirty);
    }
  }, [setIsDirty, isDirty]);

  const leaseSubject = useWatch({ control, name: 'leaseSubject' });

  const history = useHistory();

  const handleOnDrawUp = useCallback(
    (event) => {
      if (drawUpEnabled) {
        history.push(`/quotas/calculator/${quota.quotaId}/drawupcontract`);
      }
    },
    [drawUpEnabled, history, quota.quotaId]
  );

  const handleOrderPayment = useCallback(() => {
    onOpenOrderPayment();
  }, [onOpenOrderPayment]);

  const contractNumber = watch('contractNumber');

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className={classes.panels}>
          <Accordion defaultExpanded={true} disabled>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="subtitle1">{t('GeneralInfo')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Grid container columnSpacing={2} rowSpacing={2.5}>
                <Grid md={4} xs={24} item>
                  <Input
                    label={`${t('Contract number')}`}
                    name="contractNumber"
                    control={control}
                    disabled={true}
                  />
                </Grid>
                {contractNumber !== '' && (
                  <Grid md={4} xs={24} item>
                    <Select name="contractType" control={control} label={t('Contract type')}>
                      <MenuItem value={ContractType.Lease}>{t('ContractType.Lease')}</MenuItem>
                      <MenuItem value={ContractType.Loan}>{t('ContractType.Loan')}</MenuItem>
                      <MenuItem value={ContractType.LeaseNoVAT}>
                        {t('ContractType.LeaseNoVAT')}
                      </MenuItem>
                    </Select>
                  </Grid>
                )}
                <Grid item md={4} xs={24}>
                  <DatePicker label={`${t('Date')}`} name="date" control={control} />
                </Grid>
                <Grid item md={12} xs={24}>
                  <Input label={`${t('Lease subject')}`} name="leaseSubject" control={control} />
                </Grid>
                <Grid item xs={24}>
                  <Input
                    label={`${t('Lease subject in document')}`}
                    name="leaseSubjectInDocument"
                    control={control}
                  />
                </Grid>
                <Grid item xs={22}>
                  <Select label={t('Depreciation.SPI')} name="depreciation.group" control={control}>
                    <MenuItem value={DepreciationGroup.Group1}>{t('Depreciation.Group1')}</MenuItem>
                    <MenuItem value={DepreciationGroup.Group2}>{t('Depreciation.Group2')}</MenuItem>
                    <MenuItem value={DepreciationGroup.Group3}>{t('Depreciation.Group3')}</MenuItem>
                    <MenuItem value={DepreciationGroup.Group4}>{t('Depreciation.Group4')}</MenuItem>
                    <MenuItem value={DepreciationGroup.Group5}>{t('Depreciation.Group5')}</MenuItem>
                    <MenuItem value={DepreciationGroup.Group6}>{t('Depreciation.Group6')}</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <DepreciationCoefficientSelect control={control} />
                </Grid>
                <Grid item xs={24}>
                  <Select label={t('Region')} name="region" control={control}>
                    <MenuItem value={Region.RussiaExceptRepublics}>
                      {t('Regions.RussiaExceptRepublics')}
                    </MenuItem>
                    <MenuItem value={Region.RussiaCisEurope}>
                      {t('Regions.RussiaCisEurope')}
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={24}>
                  <Typography variant={'body2'} className={classes.exceptions}>
                    {t('Regions.Exceptions')}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="subtitle1">{t('Parties to the deal')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Grid container columnSpacing={2} rowSpacing={2.5}>
                <Grid md={12} xs={24} item>
                  <CounterpartyAutocompete
                    type={CounterpartyType.lessor}
                    control={control}
                    setValue={setValue}
                  />
                </Grid>
                <Grid item md={12} xs={24}>
                  <HeadSelect type={CounterpartyType.lessor} control={control} />
                </Grid>
                <Grid md={12} xs={24} item>
                  <CounterpartyAutocompete
                    type={CounterpartyType.dealer}
                    control={control}
                    setValue={setValue}
                  />
                </Grid>
                <Grid item md={12} xs={24}>
                  <HeadSelect type={CounterpartyType.dealer} control={control} />
                </Grid>
                <Grid md={12} xs={24} item>
                  <CounterpartyAutocompete
                    type={CounterpartyType.lessee}
                    control={control}
                    setValue={setValue}
                  />
                </Grid>
                <Grid item md={12} xs={24}>
                  <HeadSelect type={CounterpartyType.lessee} control={control} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="subtitle1">{t('Conditions')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Grid container columnSpacing={2} rowSpacing={2.5}>
                <Grid md={5} xs={24} item>
                  <Input
                    label={`${t('Sales prepayment in %')}`}
                    name="prepaymentPercents"
                    control={control}
                    onChange={(evt) => {
                      const val = parseInt(evt.target.value, 10);
                      if (!isNaN(val)) {
                        setValue(
                          'paymentPercents',
                          (100 - parseInt(evt.target.value, 10)).toString()
                        );
                      }
                    }}
                    rules={{
                      pattern: /^[0-9,\-\s]+$/i,
                      min: {
                        value: 0,
                        message: 'Минимум 0%',
                      },
                      max: {
                        value: 100,
                        message: 'Максимум 100%',
                      },
                    }}
                  />
                </Grid>
                <Grid md={5} xs={24} item>
                  <Input
                    label={`${t('Advance payment period, days')}`}
                    name="prepaymentDayTime"
                    control={control}
                  />
                </Grid>
                <Grid xs={24} item>
                  <Select
                    label={`${t('Prepayment conditions')}`}
                    name="prepaymentConditionType"
                    control={control}
                  >
                    <MenuItem value={ContractPaymentConditionType.None}>&nbsp;</MenuItem>
                    <MenuItem value={ContractPaymentConditionType.AfterObligation}>
                      После исполнения Лизингополучателем своих обязательств по Договору лизинга по
                      уплате авансового платежа и комиссии за организацию лизинговой сделки.
                    </MenuItem>
                    <MenuItem value={ContractPaymentConditionType.UPD}>
                      Продавца следующих документов в оригинале: УПД / товарной накладной и
                      счета-фактуры, а также подписанного Акта приемки-передачи Оборудования без
                      каких-либо претензий Покупателя и/или Лизингополучателя по качеству,
                      количеству и комплектности Оборудования.
                    </MenuItem>
                    <MenuItem value={ContractPaymentConditionType.WrittenNotice}>
                      С момента получения Покупателем письменного уведомления о готовности
                      оборудования к отгрузке со склада Продавца.
                    </MenuItem>
                  </Select>
                </Grid>

                <Grid md={5} xs={24} item>
                  <Input
                    label={`${t('Sales payment in %')}`}
                    name="paymentPercents"
                    control={control}
                    onChange={(evt) => {
                      const val = parseInt(evt.target.value, 10);
                      if (!isNaN(val)) {
                        setValue(
                          'prepaymentPercents',
                          (100 - parseInt(evt.target.value, 10)).toString()
                        );
                      }
                    }}
                    rules={{
                      pattern: /^[0-9,\-\s]+$/i,
                      min: {
                        value: 0,
                        message: 'Минимум 0%',
                      },
                      max: {
                        value: 100,
                        message: 'Максимум 100%',
                      },
                    }}
                  />
                </Grid>
                <Grid item md={5} xs={24}>
                  <Input
                    label={`${t('The deadline for payment of the main payment, days')}`}
                    name="paymentDayTime"
                    control={control}
                  />
                </Grid>
                <Grid item xs={24}>
                  <Select
                    label={`${t('Payment conditions')}`}
                    name="paymentConditionType"
                    control={control}
                  >
                    <MenuItem value={ContractPaymentConditionType.None}>&nbsp;</MenuItem>
                    <MenuItem value={ContractPaymentConditionType.AfterObligation}>
                      После исполнения Лизингополучателем своих обязательств по Договору лизинга по
                      уплате авансового платежа и комиссии за организацию лизинговой сделки.
                    </MenuItem>
                    <MenuItem value={ContractPaymentConditionType.UPD}>
                      Продавца следующих документов в оригинале: УПД / товарной накладной и
                      счета-фактуры, а также подписанного Акта приемки-передачи Оборудования без
                      каких-либо претензий Покупателя и/или Лизингополучателя по качеству,
                      количеству и комплектности Оборудования.
                    </MenuItem>
                    <MenuItem value={ContractPaymentConditionType.WrittenNotice}>
                      С момента получения Покупателем письменного уведомления о готовности
                      оборудования к отгрузке со склада Продавца.
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={24}>
                  <Input
                    label={`${t('Payment currency rate')}`}
                    name="paymentCurrencyRate"
                    control={control}
                  />
                </Grid>
                <Grid item xs={21}>
                  <DatePicker
                    label={`${t('Delivery time')}`}
                    name="expectedShippingDate"
                    control={control}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    label={`${t('Prefunding')}`}
                    name="prefunding"
                    control={control}
                    labelOn={''}
                    labelOff={''}
                  />
                </Grid>
                <Grid item xs={24}>
                  <Input
                    label={`${t('Shipping address')}`}
                    name="shippingAddress"
                    control={control}
                  />
                </Grid>
                <Grid item xs={24}>
                  <Select
                    label={t('Prepayment advance order')}
                    name="prepaymentAdvanceOrder"
                    control={control}
                  >
                    <MenuItem value={PrepaymentAdvanceOrder.EvenlyDuringLeaseTerm}>
                      {t('PrepaymentAdvanceOrder.EvenlyDuringLeaseTerm')}
                    </MenuItem>
                    <MenuItem value={PrepaymentAdvanceOrder.EvenlyDuringFirst12Months}>
                      {t('PrepaymentAdvanceOrder.EvenlyDuringFirst12Months')}
                    </MenuItem>
                    <MenuItem value={PrepaymentAdvanceOrder.OneTimeWithFirstPayment}>
                      {t('PrepaymentAdvanceOrder.OneTimeWithFirstPayment')}
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={24}>
                  <Input
                    multiline
                    label={`${t('Specifications of nomenclatures')}`}
                    name="specificationsOfNomenclatures"
                    control={control}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <PrintableTemplateList quotaId={quota.quotaId} kind={TemplateKind.Contract} />
        </div>
        {isTask ? (
          <Paper square className={classes.actionsTask}>
            <Role role={ApplicationRoles.allExceptAssetManager}>
              <div className={classes.actionButton}>
                <Button
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting || isLoading || isLocked}
                  onClick={onSubmit}
                >
                  {t('Save')}
                </Button>
              </div>
            </Role>
            <Role role={[ApplicationRole.Admin, ApplicationRole.InsideSales]}>
              {drawUpEnabled && !quota.isLocked && (
                <div className={classes.actionButton}>
                  <Button
                    color="primary"
                    size="medium"
                    type="button"
                    variant="outlined"
                    disabled={isSubmitting || isLoading || !canExecuteContract}
                    className={newNomenclatures.length > 0 ? 'Mui-disabled' : ''}
                    onClick={(event) => handleOnDrawUp(event)}
                    startIcon={
                      newNomenclatures.length > 0 && (
                        <WarningTooltip
                          arrow
                          placement="bottom-start"
                          className={classes.warningIcon}
                          onOpenNomenclatures={onOpenNomenclatures}
                          taskIds={
                            newNomenclatures.filter((item: any) => !item.taskId).length > 0
                              ? []
                              : newNomenclatures
                                  .filter((item: any) => item.taskId)
                                  .map((item: any) => item.taskId)
                          }
                        />
                      )
                    }
                  >
                    {t('Draw up contract')}
                  </Button>
                </div>
              )}
            </Role>
            <Role
              role={[
                ApplicationRole.Admin,
                ApplicationRole.SuperSalesManager,
                ApplicationRole.SalesManager,
                ApplicationRole.RiskManager,
              ]}
            >
              <div className={classes.actionButton}>
                <Button
                  color="primary"
                  size="medium"
                  type="button"
                  variant="outlined"
                  disabled={isSubmitting || isLoading || allowedPaymentTypes.length === 0}
                  onClick={handleOrderPayment}
                >
                  {t('Order payment')}
                </Button>
              </div>
            </Role>
          </Paper>
        ) : (
          <Portal container={document.body}>
            <Paper square className={classes.actions}>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <div className={classes.actionButton}>
                  <Button
                    color="primary"
                    size="medium"
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || isLoading || isLocked}
                    onClick={onSubmit}
                  >
                    {t('Save')}
                  </Button>
                </div>
              </Role>
              <Role role={[ApplicationRole.Admin, ApplicationRole.InsideSales]}>
                {drawUpEnabled && !quota.isLocked && (
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      onClick={(event) => handleOnDrawUp(event)}
                      disabled={isLoading || isSubmitting || !canExecuteContract}
                      className={newNomenclatures.length > 0 ? 'Mui-disabled' : ''}
                      startIcon={
                        newNomenclatures.length > 0 && (
                          <WarningTooltip
                            arrow
                            placement="bottom-start"
                            className={classes.warningIcon}
                            onOpenNomenclatures={onOpenNomenclatures}
                            taskIds={
                              newNomenclatures.filter((item: any) => !item.taskId).length > 0
                                ? []
                                : newNomenclatures
                                    .filter((item: any) => item.taskId)
                                    .map((item: any) => item.taskId)
                            }
                          />
                        )
                      }
                    >
                      {t('Draw up contract')}
                    </Button>
                  </div>
                )}
              </Role>
              <Role
                role={[
                  ApplicationRole.Admin,
                  ApplicationRole.SuperSalesManager,
                  ApplicationRole.SalesManager,
                  ApplicationRole.RiskManager,
                  ApplicationRole.InsideSales,
                ]}
              >
                <div className={classes.actionButton}>
                  <Button
                    color="primary"
                    size="medium"
                    type="button"
                    variant="outlined"
                    disabled={isSubmitting || isLoading || allowedPaymentTypes.length === 0}
                    onClick={handleOrderPayment}
                  >
                    {t('Order payment')}
                  </Button>
                </div>
              </Role>
            </Paper>
          </Portal>
        )}
      </form>
      <ModalForm open={openOrderPayment} onClose={onCloseOrderPayment}>
        <OrderPaymentForm
          quotaId={quota?.quotaId}
          leaseSubject={leaseSubject}
          allowedPaymentTypes={allowedPaymentTypes}
          onSuccess={() => {}}
        />
      </ModalForm>
      {quota?.quotaId && newNomenclatures.length > 0 && (
        <ModalForm open={openNomenclatures} onClose={onCloseNomenclatures}>
          <NomenclaturesRequestForm
            newNomenclatures={newNomenclatures.filter((item: any) => !item.taskId)}
            quotaId={quota?.quotaId}
            onClose={onCloseNomenclatures}
          />
        </ModalForm>
      )}
    </>
  );
};
