import {
  CalculationMethod,
  AmountType,
  InsuranceFranchise,
  Currency,
  BalanceHolder,
  Telematics,
  AgentFeeRecipient,
  AgentFeeRefund,
  AuditType,
  AuditRegularity,
  CalculationMethodType,
  SeasonalPaymentType,
} from 'schema';
import { CalculationFormValues } from './types';
import { useCalculationSettingsQuery } from 'services';

const getSeasonStartDate = () => {
  return new Date().toISOString();
};

const defaultCalculationValues: CalculationFormValues = {
  vatEnabled: true,
  calculationMethod: CalculationMethod.Annuity,
  numberOfItems: 1,
  numberOfMonths: 60,
  marginPercents: 6.0,
  cofPercents: 10.0,
  cofAddPercents: 0.0,
  prepayment: {
    type: AmountType.Percents,
    value: 20.0,
  },
  tradeFee: 1.0,
  insuranceFranchise: InsuranceFranchise.None,
  year: new Date().getFullYear(),
  secondHand: false,
  currencySale: Currency.Ruble,
  currencyLease: Currency.Ruble,
  currencyRate: 1.0,
  currencyCommission: false,
  balanceHolder: BalanceHolder.Lessor,
  hasPropertyTax: false,
  hasInsurance: false,
  hasVehicleRegistration: false,
  telematics: Telematics.None,
  agentFeeRecipient: AgentFeeRecipient.Entity,
  agentFeeRefund: AgentFeeRefund.Evenly,
  hasAudit: false,
  isSubleasing: false,
  auditType: AuditType.Desk,
  auditRegularity: AuditRegularity.Month12,
  hasFeePriceRule: true,
  hasInsurancePriceRule: false,
  hasTelematicsPriceRule: false,
  hasSubsidyPriceRule: true,
  calculationMethodType: CalculationMethodType.Forward,
  save: false,
  seasonalPaymentOptions: {
    paymentType: SeasonalPaymentType.MonthlyPayment,
    date: getSeasonStartDate(),
    hasCyclicity: false,
  },
  paymentOptions: Array.from(Array(60)).map((_value, index) => {
    return {
      number: index + 1,
      isPreferential: false,
      isPayment: true,
      value: 1.0,
    };
  }),
  nomenclatures: [
    {
      name: '',
      count: 1,
      year: new Date().getFullYear(),
      isSecondHand: false,
    },
  ],
};

export const useDefaultCalculationValues = () => {
  const { data, isLoading } = useCalculationSettingsQuery();

  if (isLoading || data === undefined) {
    return undefined;
  }

  const values: CalculationFormValues = {
    ...defaultCalculationValues,
    marginPercents: data.margin,
    cofPercents: data.cof,
    tradeFee: data.tradeFee,
    vatPercents: data.vat,
    cofAddPercents: data.cofAdd,
    vatEnabled: true,
    isAgentFeeWithNds: true,
  };

  return values;
};
