import { createStyles, makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import {
  ExternalQuotaCalculation,
  ExternalQuotaSortBy,
  ExternalQuotaStatus,
} from 'schema/serverTypes';
import clsx from 'clsx';
import { TableSortLabel } from '../data-grid/TableSortLabel';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { SortingProps } from '../data-grid/types';

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
      boxShadow: '0px 0px 20px 0px rgb(56 60 97 / 10%)',
      color: theme.palette.common.black,
      display: 'grid',
      gridTemplateColumns: '110px 20fr 15fr 33fr 20fr 120px 125px',
    },
    th: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 16,
      paddingBottom: 16,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    rowWrapper: {
      display: 'contents',
      backgroundColor: 'green',
      '&:hover > div': {
        backgroundColor: theme.palette.background.light,
      },
      '&:hover $execute': {
        display: 'block',
      },
      '& > div': {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: `2px solid ${theme.palette.background.default}`,
      },
    },
    first: {
      paddingLeft: 20,
      paddingRight: 12,
    },
    managerName: {
      color: theme.palette.text.secondary,
    },
    contractStatus: {
      color: theme.palette.text.secondary,
      fontSize: '11px',
    },
    values: {
      gridColumn: '3/7',
      display: 'grid',
      gridColumnGap: 12,
      gridRowGap: 8,
      gridTemplateColumns: '33fr 20fr 100px 30px',
    },
    debt: {
      textAlign: 'center',
    },
    comments: {
      paddingRight: 20,
      position: 'relative',
      '&:after': {
        content: '""',
        pointerEvents: 'none',
        display: 'block',
        position: 'absolute',
        left: 6,
        top: 0,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        width: 6,
        height: 6,
      },
      '&$notCommented:after': {
        backgroundColor: theme.palette.error.main,
      },
      '&$commented:after': {
        backgroundColor: theme.palette.green.main,
      },
    },
    notCommented: {},
    commented: {},
    received: {},
    ok: {
      color: theme.palette.green.main,
    },
    right: {
      textAlign: 'right',
    },
    star: {
      marginLeft: -23,
      marginRight: 10,
    },
    status: {
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: 8,
        height: 8,
        borderRadius: '50%',
        marginRight: theme.spacing(0.5),
        backgroundColor: 'black',
      },
      '&.created:before': {
        backgroundColor: theme.palette.info.main,
      },
      '&.inProgress:before': {
        backgroundColor: theme.palette.attention.main,
      },
      '&.closed:before': {
        backgroundColor: theme.palette.green.main,
      },
    },
    user: {
      position: 'relative',
    },
    execute: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'none',
    },
    client: {
      color: theme.palette.text.secondary,
    },
  })
);

type RequestsTableProps = {
  rows: ExternalQuotaCalculation[];
  isLoading: boolean;
  sorting: SortingProps;
};

export const RequestsTable = (props: RequestsTableProps) => {
  const classes = useStyles();
  const { rows, sorting } = props;

  return (
    <>
      <div className={classes.table}>
        <div className={clsx(classes.th, classes.first)}>ID</div>
        <div className={clsx(classes.th)}>Тип Заявки</div>
        <div className={clsx(classes.th)}>Регион</div>
        <div className={clsx(classes.th)}>Вид техники</div>
        <div className={clsx(classes.th)}>Ответственный</div>
        <div className={clsx(classes.th)}>Статус</div>
        <div className={clsx(classes.th)}>
          <TableSortLabel columnName={ExternalQuotaSortBy.CreatedDate} sorting={sorting}>
            Дата
          </TableSortLabel>
        </div>
        {rows.map((row: ExternalQuotaCalculation) => {
          return <TableItem key={row.id} {...row} />;
        })}
      </div>
    </>
  );
};

const TableItem = (props: ExternalQuotaCalculation) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    id,
    input,
    lessee,
    status,
    createdDate,
    user,
    //quotaId,
    client,
    requestType,
  } = props;

  return (
    <div className={classes.rowWrapper}>
      <div className={classes.first}>{id}</div>
      <div>
        {requestType}
        <br />
        <span className={classes.client}>{client.name}</span>
      </div>
      <div>{lessee?.region}</div>
      <div>{input?.nomenclature}</div>
      <div className={classes.user}>
        {user?.name}
        {!user?.name && (
          <Button
            to={`/requests/quota`}
            className={classes.execute}
            color="primary"
            size="medium"
            type="button"
            variant="contained"
          >
            {t('Execute')}
          </Button>
        )}
      </div>
      <div className={clsx(classes.status, status)}>
        {status === ExternalQuotaStatus.Created ? 'Открыта' : 'В работе'}
      </div>
      <div>{dayjs(createdDate).format('DD.MM.YYYY HH:mm')}</div>
    </div>
  );
};
