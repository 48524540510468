import { Switch } from 'components';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { CalculatorFormValues } from '../types';
import { useTranslation } from 'react-i18next';
import { AgentFeeRecipient } from 'schema';
import { useEffect } from 'react';

export type AgentFeeWithNdsSwitchProps = {
  control: Control<CalculatorFormValues, any>;
  setValue: UseFormSetValue<CalculatorFormValues>;
};

export const AgentFeeWithNdsSwitch = (props: AgentFeeWithNdsSwitchProps) => {
  const { control, setValue } = props;

  const { t } = useTranslation();

  const agentFeeRecipient = useWatch({ control, name: 'agentFeeRecipient' });

  useEffect(() => {
    if (agentFeeRecipient === AgentFeeRecipient.Individual) setValue('isAgentFeeWithNds', false);
  }, [agentFeeRecipient, setValue]);

  return agentFeeRecipient !== AgentFeeRecipient.Individual ? (
    <Switch name="isAgentFeeWithNds" label={t('VAT payer')} type="checkbox" control={control} />
  ) : null;
};
