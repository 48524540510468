import { useForm } from 'react-hook-form';
import { CalculatorFormValues, UseCalculatorFormProps } from '../CalculationForm/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  CalculationResult,
  CreatedQuotaResult,
  QuotaCalculationInput,
  ValidationProblemDetails,
} from 'schema/serverTypes';
import { calculateGeneralRatePercents } from '../CalculationForm/calculations';
import { getQuotaCalculationInput } from '../CalculationForm/getQuotaCalculationInput';
import { useQuotasBackendMutation } from 'services/api/useQuotasBackend';
import { useTranslation } from 'react-i18next';
import { useToast } from 'components';
import { useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';

export const useCalculatorForm = (props: UseCalculatorFormProps) => {
  const { defaultValues } = props;

  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [calculationResult, setCalculationResult] = useState<CalculationResult | undefined>();
  const [error, setError] = useState<ValidationProblemDetails | null>(null);
  const resultRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (calculationResult) {
      resultRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [calculationResult]);

  const { marginPercents = 6.0, cofPercents = 17 } = defaultValues;
  const generalRatePercents = calculateGeneralRatePercents(cofPercents, marginPercents);

  const form = useForm<CalculatorFormValues>({
    mode: 'onBlur',
    defaultValues,
  });

  const { handleSubmit, setValue } = form;

  const { mutateAsync: calculateAsync, isLoading: isCalculating } = useQuotasBackendMutation<
    CalculatorFormValues,
    CalculationResult
  >('calculation', {
    onSuccess: (result) => {
      toast(t('CalculationSuccessMessage'), 'success');
      setCalculationResult(result);
      setValue('cofPercents', result.cofPercents);

      //refetch lessee for new rating DLLCRMV2-1346
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' &&
            (query.queryKey as string).indexOf('type=lessee') >= 0
          );
        },
      });
    },
    onError: (error) => {
      toast(t('CalculationErrorMessage'), 'error');
      setError(error);
    },
  });

  const { mutateAsync: createAsync, isLoading: isCreating } = useQuotasBackendMutation<
    QuotaCalculationInput,
    CreatedQuotaResult
  >('', {
    onError: (error) => {
      setError(error);
      toast(t('SaveErrorMessage'), 'error');
    },
    onSuccess: (result) => {
      toast(t('SaveSuccessMessage'), 'success');
      setCalculationResult(result.calculationResult);

      queryClient.invalidateQueries({
        predicate: (query) => {
          if (Array.isArray(query.queryKey)) {
            const key = query.queryKey as Array<string>;
            if (key[0] === 'quotas') {
              return true;
            }
          }
          return false;
        },
      });

      //refetch lessee for new rating DLLCRMV2-1346
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' &&
            (query.queryKey as string).indexOf('type=lessee') >= 0
          );
        },
      });
    },
  });

  const { push } = useHistory();
  const { path } = useRouteMatch();

  const onSubmit = useMemo(() => {
    const submit = async (values: CalculatorFormValues) => {
      const request = getQuotaCalculationInput(values);
      if (values.save) {
        const result = await createAsync(request);
        setValue('leaseItemCost', result.calculationResult.leaseItemCost);
        push(`${path}/${result.quotaId}`);
      } else {
        const calcResult = await calculateAsync(request);
        setValue('leaseItemCost', calcResult.leaseItemCost);
      }
    };
    return handleSubmit(submit);
  }, [path, createAsync, setValue, calculateAsync, handleSubmit, push]);

  const formProps = useMemo(() => {
    return {
      cofPercents,
      marginPercents,
      generalRatePercents,
      resultRef,
      error,
      defaultValues,
      isLoading: isCalculating || isCreating,
      calculationResult,
      form,
      onSubmit,
    };
  }, [
    cofPercents,
    marginPercents,
    generalRatePercents,
    resultRef,
    error,
    defaultValues,
    isCalculating,
    isCreating,
    calculationResult,
    form,
    onSubmit,
  ]);

  return formProps;
};
