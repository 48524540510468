import { useTranslation } from 'react-i18next';
import { AmountInput } from '../AmountInput';
import { useCalculationFormContext } from '../types';
import { useWatch } from 'react-hook-form';
import { CalculationMethodType } from 'schema/serverTypes';
import { useEffect } from 'react';

export const SubsidyDealerInput = () => {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useCalculationFormContext();
  const calculationMethodType = useWatch({ control, name: 'calculationMethodType' });

  const disabled = calculationMethodType !== CalculationMethodType.Forward;

  useEffect(() => {
    const currentSubsidyDealer = getValues('subsidyDealer');
    if (
      calculationMethodType !== CalculationMethodType.Forward &&
      currentSubsidyDealer !== undefined
    ) {
      setValue('subsidyDealer', undefined);
    }
  }, [calculationMethodType, setValue, getValues]);

  return (
    <AmountInput
      name="subsidyDealer"
      label={t('Subsidy')}
      useSaleCurrency={true}
      disabled={disabled}
    />
  );
};
