import MenuItem from '@material-ui/core/MenuItem';
import { Select } from 'components/form';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCalculationFormContext } from '../types';

export const KrimistopTelematicsCountInput = () => {
  const { t } = useTranslation();
  const { control } = useCalculationFormContext();
  const numberOfItems = useWatch({
    control,
    name: 'numberOfItems',
  });

  const total = (numberOfItems ?? 1) * 4;

  return (
    <Select name="krimistopTelematicsCount" label={t('NumberOfItems')} required>
      {Array.from(Array(total)).map((_, i) => {
        return (
          <MenuItem key={i + 1} value={i + 1}>
            {i + 1}
          </MenuItem>
        );
      })}
    </Select>
  );
};
