import { useQueryClient } from 'react-query';
import {
  AddQuotaLostReasonRequest,
  PageOptions,
  QuotaLostReason,
  QuotaLostReasonFilter,
  QuotaLostReasonPagedList,
  QuotaLostReasonSorting,
  UpdateQuotaLostReasonViewModel,
} from 'schema/serverTypes';
import { BackendQueryOptions, EmptyResponse, createOptions, createUrlFromParts } from './types';
import { useBackendQuery, useBackendMutation, MutationOptions } from './useBackend';

const entity = 'quotaLostReasons';

const useQuotaLostReasonBackendQuery = <TResponse>(options: BackendQueryOptions<TResponse>) => {
  let queryKey = entity;
  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

export type UseQuotaLostReasonQueryProps = QuotaLostReasonFilter &
  Partial<QuotaLostReasonSorting> &
  PageOptions;

export const useQuotaLostReasonListQuery = (props: UseQuotaLostReasonQueryProps) => {
  const { search, order, sortBy, page = 1, pageSize = 20 } = props;
  let searchParams = new URLSearchParams();

  if (search !== undefined && search !== '') {
    searchParams.append('search', search);
  }

  if (sortBy) {
    searchParams.append('sortBy', sortBy);
  }

  if (order) {
    searchParams.append('order', order);
  }

  searchParams.append('page', page.toString());
  searchParams.append('pageSize', pageSize.toString());

  return useQuotaLostReasonBackendQuery<QuotaLostReasonPagedList>({
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};

export const useQuotaLostReasonQuery = (id: number) =>
  useQuotaLostReasonBackendQuery<QuotaLostReason>({
    relativeUrl: id.toString(),
  });

export const useAddQuotaLostReasonMutation = (
  options: MutationOptions<AddQuotaLostReasonRequest, QuotaLostReason> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const onSuccess = options?.onSuccess;

  return useBackendMutation(createUrlFromParts(entity), {
    ...options,
    onSuccess: (data, variables, context) => {
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (Array.isArray(query.queryKey)) {
            const key = query.queryKey as Array<string>;
            if (key[0] === entity) {
              return true;
            }
          }
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string)?.indexOf(entity) > -1
          );
        },
      });
    },
    method: 'POST',
  });
};

export const useUpdateQuotaLostReasonMutation = (
  id: number,
  options: MutationOptions<UpdateQuotaLostReasonViewModel, QuotaLostReason> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const onSuccess = options?.onSuccess;

  return useBackendMutation(createUrlFromParts(entity, id.toString()), {
    ...options,
    onSuccess: (data, variables, context) => {
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (Array.isArray(query.queryKey)) {
            const key = query.queryKey as Array<string>;
            if (key[0] === entity) {
              return true;
            }
          }
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string)?.indexOf(entity) > -1
          );
        },
      });
    },
    method: 'PUT',
  });
};

export const useDeleteQuotaLostReasonMutation = (
  id: number,
  options: MutationOptions<UpdateQuotaLostReasonViewModel, EmptyResponse> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const onSuccess = options?.onSuccess;

  return useBackendMutation(createUrlFromParts(entity, id.toString()), {
    ...options,
    onSuccess: (data, variables, context) => {
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (Array.isArray(query.queryKey)) {
            const key = query.queryKey as Array<string>;
            if (key[0] === entity) {
              return true;
            }
          }
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string)?.indexOf(entity) > -1
          );
        },
      });
    },
    method: 'DELETE',
  });
};
