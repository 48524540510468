import { CalculationMethod, QuotaCalculationInput, Telematics } from 'schema';
import { CalculationFormValues } from './types';

export const getQuotaCalculationInput = (values: CalculationFormValues) => {
  const {
    vatEnabled,
    save,
    calculationMethod,
    itemPrice,
    numberOfItems,
    numberOfMonths,
    prepayment,
    itemDiscount,
    subsidyVendor,
    subsidyDealer,
    agentFee,
    agentFeeRecipient,
    insuranceRatePercents,
    telematicsOneTimeAmount,
    telematicsMonthlyAmount,
    tradeFee,
    residualValue,
    marginPercents,
    cofPercents,
    cofAddPercents,
    vatPercents,
    calculationMethodType,
    irrPercents,
    rizeInPricePercents,
    currencySale,
    currencyRate,
    currencyLease,
    leaseItemCost,
    tradeFeeAmount,
    generalRatePercents,
    fundingAmount,
    dealer,
    lessee,
    insuranceCompany,
    hasResidualValue,
    hasInsurance,
    seasonalPaymentOptions,
    paymentOptions,
    nomenclatures = [],
    ...rest
  } = values;
  const { agreement: agg, lesseeName, ...agreement } = rest;

  const request: QuotaCalculationInput = {
    calculationMethod,
    calculationMethodType,
    itemPrice,
    numberOfItems,
    numberOfMonths,
    prepayment,
    itemDiscount,
    subsidyVendor,
    subsidyDealer,
    agentFee,
    agentFeeRecipient,
    insuranceRatePercents,
    telematicsOneTimeAmount:
      agreement.telematics === Telematics.None ? undefined : telematicsOneTimeAmount,
    telematicsMonthlyAmount:
      agreement.telematics === Telematics.None ? undefined : telematicsMonthlyAmount,
    tradeFee,
    residualValue,
    hasResidualValue,
    hasInsurance,
    marginPercents,
    cofPercents,
    cofAddPercents,
    vatPercents,
    agreement,
    currencySale,
    currencyRate,
    currencyLease,
    leaseItemCost,
    dealer,
    lessee,
    lesseeName,
    insuranceCompany,
    seasonalPaymentOptions,
    paymentOptions:
      calculationMethod === CalculationMethod.Seasonal
        ? paymentOptions
        : calculationMethod === CalculationMethod.SuperSeasonal
        ? paymentOptions?.slice(0, 12)
        : undefined,
    rizeInPricePercents,
    irrPercents,
    nomenclatures,
  };

  return request;
};
