import { TemplatePrintViewModel } from 'schema/serverTypes';
import { UseTemplatesQueryProps, useTemplatesQuery } from 'services';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import { IconSprite } from '../../icons';
import icons from 'img/icons/document.svg';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import useFileDownloader from '../../utils/useFileDownloader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      width: '100%',
    },
    details: {
      width: '100%',
    },
    templateList: {
      width: '100%',
    },
    template: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      border: '1px solid ' + theme.palette.secondary.main,
      marginBottom: theme.spacing(2),
      width: '100%',
      padding: theme.spacing(1.5),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    title: {
      padding: theme.spacing(0, 2.5, 1.5),
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      margin: theme.spacing(0, -2.5, 2),
      display: 'flex',
      justifyContent: 'space-between',
    },
    fileName: {
      color: theme.palette.secondary.dark,
    },
    fileDescription: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    text: {
      width: '100%',
    },
    link: {
      display: 'flex',
      cursor: 'pointer',
    },
    icon: {
      width: 28,
      height: 29,
      marginRight: theme.spacing(1),
    },
    downloadIcon: {
      width: 34,
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.light,
    },
    close: {
      cursor: 'pointer',
    },
  })
);

export type PrintableTemplateListProps = UseTemplatesQueryProps & {
  expanded?: boolean;
};

export type PrintableTemplateProps = {
  template: TemplatePrintViewModel;
};

const TemplateItem = ({ template }: PrintableTemplateProps) => {
  const classes = useStyles();
  const { title, description, printUrl, fileName } = template;

  const downloadFile = useFileDownloader();
  const getFile = useCallback(
    async (ev) => {
      await downloadFile(ev, printUrl, fileName, undefined, { method: 'POST' });
    },
    [downloadFile, fileName, printUrl]
  );

  return (
    <li className={classes.template}>
      <div onClick={getFile} className={classes.link}>
        <img alt={title} className={classes.icon} src={icons} />
        <div className={classes.text}>
          <Typography variant="h5" className={classes.fileName}>
            {title}
          </Typography>
          <Typography variant="body2" className={classes.fileDescription}>
            {description}
          </Typography>
        </div>
        <div className={classes.downloadIcon}>
          <IconSprite
            width="14px"
            height="13px"
            color={theme.palette.secondary.dark}
            icon="download"
          />
        </div>
      </div>
    </li>
  );
};

export const PrintableTemplateList = (props: PrintableTemplateListProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data: templates = [] } = useTemplatesQuery(props);

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary>
        <Typography variant="subtitle1">{t('Template for printing')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <ul className={classes.templateList}>
          {templates.map((template) => {
            return <TemplateItem key={template.id} template={template} />;
          })}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
};
