import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { ShipmentFormProps } from './types';
import { ShipmentItem } from './ShipmentItem';
import { useQuotasBackendQuery } from 'services/api';
import { ShipmentViewModel } from 'schema/serverTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panels: {
      marginBottom: (props: ShipmentFormProps) => (props.isTask ? '0' : '75px'),
    },
    legendList: {
      display: 'flex',
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
      '&:before': {
        content: '""',
        display: 'inline-block',
        borderRadius: '50%',
        width: 15,
        height: 15,
        marginRight: theme.spacing(1),
      },
      '&.success:before': {
        border: `3px solid ${theme.palette.green.main}`,
      },
      '&.error:before': {
        border: `3px solid ${theme.palette.chartPurple.main}`,
      },
    },
  })
);

export const ShipmentForm = (props: ShipmentFormProps) => {
  const classes = useStyles(props);

  const { quota, shipmentItemId, setIsDirty } = props;

  const { data, isLoading } = useQuotasBackendQuery<ShipmentViewModel>(
    `${quota.quotaId}/shipment`,
    ['quotas', quota.quotaId, 'shipment'],
    {
      refetchOnWindowFocus: true,
    }
  );

  let items = data?.items ?? [];

  if (shipmentItemId) {
    items = items.filter((t) => t.id === shipmentItemId);
  }
  const isLocked = data?.isLocked ?? false;
  const telematics = quota.calculationResult.telematics;

  return (
    <>
      <div className={classes.panels}>
        {items.map((item, index) => {
          return (
            <ShipmentItem
              key={item.id}
              item={item}
              index={index}
              quotaId={quota.quotaId}
              authorId={quota.user.id}
              dealer={quota.dealer}
              lessee={quota.lessee}
              lessor={data?.lessor}
              isLoading={isLoading}
              isLocked={isLocked}
              telematics={telematics}
              setIsDirty={setIsDirty}
            />
          );
        })}
      </div>
    </>
  );
};
