import { makeStyles, Theme, createStyles, Grid, Box } from '@material-ui/core';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useCounterpartyRatings } from './useCounterpartyRatings';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { RatingSortBy, SortOrder } from 'schema';
import { RatingTable } from './RatingTable';
import { Button } from '../../Button';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { RatingFilterForm } from './RatingFilterForm';
import { Role } from 'components/authentication';
import { ApplicationRoles, useCounterpartyQuery } from 'services';
import { useCreateAutomaticRatingMutation } from '../../../services/api/useRatingsBackend';
import { useToast } from '../../Toast';
import { useQueryClient } from 'react-query';
import { useCounterpartyProperties } from './useCounterpartyProperties';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 'calc(100vw - 190px - 48px - var(--scrollbar-width)) ',
      display: 'flex',
      height: '100%',
      alignItems: 'stretch',
      position: 'relative',
    },
    ratingNew: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 757,
      flex: '0 0 auto',
      boxShadow: 'none',
      '&$editMode': {
        zIndex: 5,
        boxShadow: '0px 2px 20px rgba(56, 60, 97, 0.1)',
      },
    },
    editMode: {},
    ratingArr: {
      display: 'flex',
      overflowX: 'scroll',
      boxShadow: 'none',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& a, & button': {
        marginLeft: theme.spacing(2),
      },
    },
    filter: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    compare: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: 12,
      textDecoration: 'none',
      '& > span > span': {
        backgroundColor: theme.palette.error.main,
        color: 'white',
        padding: '3px 8px',
        borderRadius: 20,
        display: 'inline-block',
        marginLeft: 8,
      },
    },
  })
);

export type RatingDataGridProps = {
  compareIds: number[];
  setCompareIds: Dispatch<SetStateAction<number[]>>;
};

export const RatingDataGrid = (props: RatingDataGridProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { compareIds, setCompareIds } = props;
  const { control, reset, setValue } = useForm<any>({
    mode: 'onBlur',
  });

  const onReset = useCallback(() => {
    reset({});
  }, [reset]);

  const users = useWatch({ control, name: 'userId' }) ?? [];
  const [sortBy, setSortBy] = useState<RatingSortBy>(RatingSortBy.FromDate);
  const [order, setOrder] = useState<SortOrder>(SortOrder.asc);
  const { ratings = [], isLoading } = useCounterpartyRatings({
    users,
    order,
    sortBy,
  });
  const { pathname: pathnameWithSlash } = useLocation();
  const pathname = pathnameWithSlash.endsWith('/')
    ? pathnameWithSlash.slice(0, -1)
    : pathnameWithSlash;
  const {
    params: { inn },
  } = useRouteMatch<{ inn: string }>();

  const toast = useToast();
  const queryClient = useQueryClient();
  const { mutateAsync } = useCreateAutomaticRatingMutation(inn, {
    onSuccess: () => {
      toast(t('RatingSuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string).indexOf('ratings') >= 0
          );
        },
      });
    },
    onError: (error) => {
      const errors = error.errors;
      Object.keys(errors)
        .map((key) => errors[key])[0]
        .map((error) => toast(error ?? t('ErrorMessage'), 'error'));
    },
  });

  const autoRatingHandler = () => {
    mutateAsync({ inn });
  };

  const { isLoading: isCounterpartyPropertiesLoading, isFinanced } = useCounterpartyProperties();
  const history = useHistory();
  const addRatingHandler = () => {
    if (typeof isFinanced === 'boolean' && !isFinanced) {
      toast('Запрет финансирования по номеру ОКВЭД. Расчет рейтинга не производится.', 'error');
    } else {
      history.push(`${pathname}/rating/add`, { tab: t('Rating') });
    }
  };

  const compareRatingHandler = () => {
    history.push(`${pathname}/rating/compare`, { tab: t('Rating') });
  };

  const { data } = useCounterpartyQuery(inn);

  const isAutoRatingEnabled =
    data !== undefined && data.opfShort !== 'ИП' && data.opfShort !== 'ГКФХ';

  return (
    <>
      <Box mb={2.5} className={classes.filter}>
        <RatingFilterForm control={control as any} onReset={onReset} setValue={setValue} />
        <Role role={ApplicationRoles.allExceptAssetManager}>
          <Box className={classes.actions}>
            {compareIds.length > 0 && (
              <Button variant="text" onClick={compareRatingHandler} className={classes.compare}>
                {t('Buttons.Compare')} <span>{compareIds.length}</span>
              </Button>
            )}
            {isAutoRatingEnabled && (
              <Button variant="contained" onClick={autoRatingHandler}>
                {t('Buttons.AutoRating')}
              </Button>
            )}
            <Button
              variant="outlined"
              disabled={isCounterpartyPropertiesLoading}
              onClick={addRatingHandler}
            >
              {t('Buttons.AddRating')}
            </Button>
          </Box>
        </Role>
      </Box>
      <Grid container item direction="column">
        <Grid item>
          <RatingTable
            rows={ratings}
            isLoading={isLoading}
            setCompareIds={setCompareIds}
            compareIds={compareIds}
            sorting={{ setOrder, setSortBy, order, sortBy }}
          />
        </Grid>
      </Grid>
    </>
  );
};
