import { FormApi } from 'final-form';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CalculationResult,
  QuotaCalculationResult,
  QuotaCalculationInput,
  ValidationProblemDetails,
  AmountType,
} from 'schema';
import { CalculationFormValues } from '../types';
import { useQuotasBackendMutation } from 'services';
import { getQuotaCalculationInput } from '../getQuotaCalculationInput';
import {
  calculatePrepaymentAmount,
  calculateFundingAmount,
  calculateTradeFeeAmount,
  calculateGeneralRatePercents,
} from '../calculations';
import { useQueryClient } from 'react-query';
import { useToast } from '../../Toast/useToast';
import { useTranslation } from 'react-i18next';

export const useQuotaCalculationForm = (quota: QuotaCalculationResult) => {
  const id = quota.quotaId;
  const toast = useToast();
  const { t } = useTranslation();

  const [calculationResult, setCalculationResult] = useState<CalculationResult>(
    quota.calculationResult
  );

  const [error, setError] = useState<ValidationProblemDetails | null>(null);
  const resultRef = useRef<HTMLDivElement | null>(null);

  const { mutateAsync: calculateAsync, isLoading: isCalculating } = useQuotasBackendMutation<
    CalculationFormValues,
    CalculationResult
  >('calculation', {
    onError: (error) => {
      toast(t('CalculationErrorMessage'), 'error');
      setError(error);
    },
    onSuccess: (result) => {
      if (result.isInvalidPrizeRizing) {
        toast(t('InvalidPrizeRisingMessage'), 'error');
      } else {
        toast(t('CalculationSuccessMessage'), 'success');
      }
      setCalculationResult(result);
      resultRef.current?.scrollIntoView({ behavior: 'smooth' });
    },
  });

  const queryClient = useQueryClient();

  const { mutateAsync: updateAsync, isLoading: isUpdating } = useQuotasBackendMutation<
    QuotaCalculationInput,
    QuotaCalculationResult
  >(`${id}`, {
    method: 'PUT',
    onError: (error) => {
      toast(t('SaveErrorMessage'), 'error');
      setError(error);
    },
    onSuccess: (quota) => {
      if (quota.calculationResult.isInvalidPrizeRizing) {
        toast(t('InvalidPrizeRisingMessage'), 'error');
      } else {
        toast(t('SaveSuccessMessage'), 'success');
      }
      setCalculationResult(quota.calculationResult);
      queryClient.invalidateQueries({
        predicate: (query) => {
          return Array.isArray(query.queryKey) && query.queryKey.indexOf('quotas') >= 0;
        },
      });
    },
  });

  const { mutateAsync: copyAsync, isLoading: isCopying } = useQuotasBackendMutation<
    QuotaCalculationInput,
    QuotaCalculationResult
  >(`${id}/copy`, {
    onError: (error) => {
      setError(error);
    },
  });

  const history = useHistory();

  const onSubmit = useCallback(
    async (
      values: CalculationFormValues,
      form: FormApi<CalculationFormValues, Partial<CalculationFormValues>>
    ) => {
      const request = getQuotaCalculationInput(values);
      if (values.copy) {
        const result = await copyAsync(request);
        if (result.quotaId) {
          history.push(`/quotas/calculator/${result.quotaId}`);
        }
      } else if (values.save) {
        const result = await updateAsync(request);
        form.change('leaseItemCost', result.calculationResult.leaseItemCost);
      } else {
        const calcResult = await calculateAsync(request);
        form.change('leaseItemCost', calcResult.leaseItemCost);
      }
    },
    [updateAsync, calculateAsync, copyAsync, history]
  );

  const {
    input,
    agreement,
    calculationResult: result,
    dealer,
    lessee,
    insuranceCompany,
    dealerName,
    lesseeName,
    insuranceCompanyName,
  } = quota;
  const { leaseItemCost } = result;
  const {
    cofPercents = 0,
    marginPercents = 0,
    itemDiscount = {
      value: 0,
      type: AmountType.Money,
    },
  } = input;

  const initialValues: CalculationFormValues = useMemo(() => {
    const { prepayment = { type: AmountType.Percents, value: 0 }, tradeFee = 0 } = input;
    const prepaymentAmount = calculatePrepaymentAmount(leaseItemCost, prepayment, itemDiscount);
    const fundingAmount = calculateFundingAmount(leaseItemCost, prepaymentAmount, itemDiscount);
    const tradeFeeAmount = calculateTradeFeeAmount(leaseItemCost, tradeFee, itemDiscount);
    const generalRatePercents = calculateGeneralRatePercents(cofPercents, marginPercents);

    return {
      ...input,
      ...agreement,
      vatEnabled: (input.vatPercents ?? 0) > 0,
      dealerName,
      lesseeInn: lessee,
      lesseeName,
      insuranceCompanyName,
      leaseItemCost,
      prepaymentAmount,
      tradeFeeAmount,
      fundingAmount,
      dealer,
      lessee,
      insuranceCompany,
      generalRatePercents,
    };
  }, [
    input,
    agreement,
    leaseItemCost,
    dealer,
    lessee,
    insuranceCompany,
    cofPercents,
    marginPercents,
    itemDiscount,
    dealerName,
    lesseeName,
    insuranceCompanyName,
  ]);

  const isLoading = isUpdating || isCalculating || isCopying;

  return {
    isLoading,
    onSubmit,
    initialValues,
    data: calculationResult,
    error,
    resultRef,
  };
};
