import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  createStyles,
  CardActions,
  Theme,
  Typography,
  Box,
  MenuItem,
} from '@material-ui/core';
import { Grid } from 'components/Grid';
import { Skeleton } from '@material-ui/lab';
import { Input, Switch } from '../form';
import { UserViewModel } from './types';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../avatar';
import { Button, Role, Select } from 'components';
import { useUserRole } from 'services/authentication/useUserRole';
import { useUpdateUserMutation } from './useEditForm';
import dayjs from 'dayjs';
import { ApplicationRole } from '../../schema';
import { LeasingProductAutocomplete } from './LeasingProductAutocomplete';

export interface UserEditFormProps {
  user: UserViewModel;
}

export const UserEditFormSkeleton = () => {
  return <Skeleton></Skeleton>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      border: 'none',
      boxShadow: 'none',
      paddingBottom: theme.spacing(1.2),
    },
    cardContent: {
      paddingTop: 20,
      paddingRight: 20,
      paddingBottom: 20,
      paddingLeft: 20,
    },
    headerWrapper: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      paddingTop: theme.spacing(5.5),
      fontWeight: 'bolder',
      textAlign: 'center',
      paddingRight: 20,
      paddingBottom: 20,
      paddingLeft: 20,
    },
    loginTime: {
      textAlign: 'center',
      fontSize: 10,
      color: theme.palette.text.primary,
    },
    actions: {
      justifyContent: 'flex-start',
    },
    cancelButton: {
      color: theme.palette.error.main,
    },
    item: {
      justifyContent: 'center',
    },
    membershipTitle: {
      marginBottom: 6,
    },
    membershipItem: {
      marginBottom: 4,
    },
    GridCloseButton: {
      backgroundColor: theme.palette.lightBlue3.main,
    },
    closeButton: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      '& img': {
        maxWidth: '10px',
      },
    },
  })
);

const useDefaultValues = (user: UserViewModel) => {
  return useMemo(() => {
    const model: UserViewModel = {
      ...user,
    };

    return model;
  }, [user]);
};

export const UserEditForm1 = (props: UserEditFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user: { id, name, userName, lastLoginTime: lastLoginDate },
  } = props;
  const { groups = [] } = props.user;
  const title = name ?? userName;
  const { isAdmin } = useUserRole();
  const disabled = !isAdmin;

  const defaultValues = useDefaultValues(props.user);

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<UserViewModel>({
    mode: 'onBlur',
    defaultValues,
  });

  const { mutateAsync, isLoading } = useUpdateUserMutation(id);

  const onSubmit = useCallback(
    async (values: UserViewModel) => {
      await mutateAsync(values);
    },
    [mutateAsync]
  );

  const lastLoginTime =
    lastLoginDate !== undefined
      ? t('LastLoginTime', {
          lastLoginDate: dayjs(lastLoginDate).format('DD.MM.YYYY'),
          lastLoginTime: dayjs(lastLoginDate).format('HH:mm'),
        })
      : undefined;

  const isActive = watch('isActive');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className={classes.root}>
        <Box display="flex" justifyContent="flex-end" className={classes.GridCloseButton} />
        <Box className={classes.headerWrapper}>
          <CardHeader className={classes.header} title={title} />
        </Box>
        <CardContent className={classes.cardContent}>
          <Grid container rowSpacing={2.5} columnSpacing={0}>
            <Grid item xs={24} className={classes.item}>
              <Box className={classes.item}>
                <Avatar size="large" />
              </Box>
            </Grid>
            {lastLoginTime && (
              <Grid item xs={24} className={classes.item}>
                <Box className={classes.loginTime}>{lastLoginTime}</Box>
              </Grid>
            )}
            <Grid item xs={24} className={classes.item}>
              <Switch
                label={isActive ? t('IsActive') : 'Уволен'}
                name="isActive"
                control={control}
                labelOn={''}
                labelOff={''}
              />
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Input label={`${t('LastName')}`} name="lastName" control={control} required={true} />
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Input label={`${t('FirstName')}`} name="firstName" control={control} />
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Input label={`${t('MiddleName')}`} name="middleName" control={control} />
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Input
                label={`${t('Email')}`}
                name="email"
                control={control}
                required={true}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Input label={`${t('PhoneNumber')}`} name="phoneNumber" control={control} />
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <Select
                name="role"
                label={t('UserType')}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('Required'),
                  },
                }}
              >
                <MenuItem>
                  <em>{t('NotSet')}</em>
                </MenuItem>
                <MenuItem value={'sales_manager'}>{t('Roles.SalesManager')}</MenuItem>
                <MenuItem value={'super_sales_manager'}>{t('Roles.SuperSalesManager')}</MenuItem>
                <MenuItem value={'sales_support'}>{t('Roles.SalesSupport')}</MenuItem>
                <MenuItem value={'dealer'}>{t('Roles.Dealer')}</MenuItem>
                <MenuItem value={'admin'}>{t('Roles.Admin')}</MenuItem>
                <MenuItem value={ApplicationRole.RiskManager}>{t('Roles.RiskManager')}</MenuItem>
                <MenuItem value={ApplicationRole.AssetManager}>{t('Roles.AssetManager')}</MenuItem>
                <MenuItem value={ApplicationRole.Vendor}>{t('Roles.Vendor')}</MenuItem>
                <MenuItem value={ApplicationRole.Compliance}>{t('Roles.Compliance')}</MenuItem>
                <MenuItem value={ApplicationRole.InsideSales}>{t('Roles.InsideSales')}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={24} className={classes.item}>
              <LeasingProductAutocomplete control={control} />
            </Grid>
            {groups.length > 0 ? (
              <Grid item xs={24} className={classes.item}>
                <Typography variant="body2" className={classes.membershipTitle}>
                  {t('Membership in groups')}
                </Typography>
                <ul>
                  {groups.map((group) => (
                    <li key={group.id} className={classes.membershipItem}>
                      <Typography variant="body2" color="secondary">
                        {group.name}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
            ) : null}
            <Role role={ApplicationRole.Admin}>
              <Grid item xs={24} className={classes.item}>
                <Typography variant="body2" className={classes.membershipTitle}>
                  Доп. возможности
                </Typography>
                <Switch
                  control={control}
                  name="isMarginAgreement"
                  labelOn="Согласование маржи"
                  labelOff="Согласование маржи"
                  type="checkbox"
                />
              </Grid>
            </Role>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            disabled={isSubmitting || isLoading}
            size="medium"
            type="submit"
            variant="contained"
          >
            {t('Save')}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
