import { makeStyles, Theme, createStyles, Paper, Portal, MenuItem } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { RatingFinancialLabels } from './RatingFinancialLabels';
import { RatingFinancialFields } from './RatingFinancialFields';
import { RatingBusinessLabels } from './RatingBusinessLabels';
import { RatingBusinessFields } from './RatingBusinessFields';
import { RatingOptionalLabels } from './RatingOptionalLabels';
import { RatingOptionalFields } from './RatingOptionalFields';
import { useForm, useWatch } from 'react-hook-form';
import { getRatingInput, RatingInputValues } from './types';
import {
  useUpdateRatingMutation,
  useRatingCalculationsQuery,
  useCalculateRatingMutation,
} from '../../../services/api/useRatingsBackend';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useToast } from '../../Toast';
import { Button } from '../../Button';
import {
  RatingAnswer6,
  RatingCalculationViewModel,
  RatingInput,
  RatingQuestionType,
  RatingViewModel,
} from 'schema/serverTypes';
import { RatingOutput } from './RatingOutput';
import { Select } from '../../form';
import dayjs from 'dayjs';
import { RatingFinancialParams } from './RatingFinancialParams';
import { RatingBusinessParams } from './RatingBusinessParams';
import { Grid } from '../../Grid';
import { ModalForm, useModalForm } from '../../Modal';
import { RatingYearsModal } from './RatingYearsModal';
import clsx from 'clsx';
import { IconArrowCopy } from '../../icons';
import { Role } from 'components/authentication';
import { ApplicationRoles } from 'services/authentication/ApplicationRoles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2),
      display: 'grid',
      gridTemplateColumns: '2fr 3fr',
      gridTemplateRows: 'auto 1fr',
      gridGap: 20,
      height: '100%',
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
    period: {
      display: 'flex',
      marginBottom: theme.spacing(2.5),
    },
    quarter: {
      width: 100,
      marginRight: theme.spacing(2),
    },
    year: {
      width: 100,
      marginRight: theme.spacing(2),
    },
    konturYearData: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
    },
    financial: {
      gridRow: '1/3',
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        flex: '0 0 auto',
        boxShadow: 'none',
      },
    },
    business: {
      gridColumn: 2,
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        flex: '0 0 auto',
        boxShadow: 'none',
      },
    },
    optional: {
      gridColumn: 2,
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        flex: '0 0 auto',
        boxShadow: 'none',
      },
    },
    output: {
      marginBottom: theme.spacing(2),
    },
    outputFinancial: {
      marginBottom: theme.spacing(2),
    },
    outputBusiness: {
      marginBottom: 75,
    },
    header: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.black,
      fontWeight: 600,
      fontSize: 12,
      padding: '10px 12px',
      marginBottom: 7,
    },
    headerItem: {
      position: 'relative',
      alignItems: 'center',
      display: 'flex',
    },
    right: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      left: 216,
      position: 'fixed',
      zIndex: 1,
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
    copyButton: {
      backgroundColor: theme.palette.background.light,
      border: '1px solid' + theme.palette.text.secondary,
      display: 'inline-block',
      marginLeft: 16,
    },
    copyContainer: {},
  })
);

const useDefaultValues = (input: RatingInput, fromDate?: string, toDate?: string) => {
  return useMemo(() => {
    const { answers, ...rest } = input;

    let quarter = '';
    let year = '';

    const fromDateObj = dayjs(fromDate);
    if (fromDateObj.isValid()) {
      quarter = fromDateObj.format('MM.DD');
      year = fromDateObj.format('YYYY');
    }

    const values: RatingInputValues & { quarter: string; year: string } = {
      ...rest,
      fromDate,
      toDate,
      quarter,
      year,
      question1: answers.find((t) => t.questionId === RatingQuestionType.Question1)?.value,
      question2: answers.find((t) => t.questionId === RatingQuestionType.Question2)?.value,
      question3: answers.find((t) => t.questionId === RatingQuestionType.Question3)?.value,
      question4: answers.find((t) => t.questionId === RatingQuestionType.Question4)?.value,
      question5: answers.find((t) => t.questionId === RatingQuestionType.Question5)?.value,
      question6: answers
        .filter((t) => t.questionId === RatingQuestionType.Question6)
        .map((t) => t.value as RatingAnswer6),
      question7: answers.find((t) => t.questionId === RatingQuestionType.Question7)?.value,
      question8: answers.find((t) => t.questionId === RatingQuestionType.Question8)?.value,
      question9: answers.find((t) => t.questionId === RatingQuestionType.Question9)?.value,
      question10: answers.find((t) => t.questionId === RatingQuestionType.Question10)?.value,
      question11: answers.find((t) => t.questionId === RatingQuestionType.Question11)?.value,
      question12: answers.find((t) => t.questionId === RatingQuestionType.Question12)?.value,
      question13: answers.find((t) => t.questionId === RatingQuestionType.Question13)?.value,
      question14: answers.find((t) => t.questionId === RatingQuestionType.Question14)?.value,
      question15: answers.find((t) => t.questionId === RatingQuestionType.Question15)?.value,
      question16: answers.find((t) => t.questionId === RatingQuestionType.Question16)?.value,
      question18: answers.find((t) => t.questionId === RatingQuestionType.Question17)?.value,
      question17: answers.find((t) => t.questionId === RatingQuestionType.Question18)?.value,
    };

    return values;
  }, [input, fromDate, toDate]);
};

export const RatingEdit = () => {
  const {
    params: { inn, id },
  } = useRouteMatch<{ inn: string; id: string }>();
  const { data = [], isLoading } = useRatingCalculationsQuery(inn, [parseInt(id, 10)]);
  const rating = !isLoading ? data[0] : null;

  return rating && <RatingEditForm rating={rating} inn={inn} id={id} />;
};

type RatingEditFormProps = {
  rating: RatingViewModel;
  inn: string;
  id: string;
};
const RatingEditForm = (props: RatingEditFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { onOpen, onClose, open } = useModalForm();
  const [result, setResult] = useState<RatingCalculationViewModel | null>(null);
  const { inn, id, rating } = props;
  const { calculationContext: context } = rating;
  const [editId, setEditId] = useState<number | null>(null);
  const { input, output, fromDate = '', toDate = '', isConfirmed } = rating;
  const defaultValues = useDefaultValues(input, fromDate, toDate);
  const resultRef = useRef<HTMLDivElement | null>(null);
  const [konturValues, setKonturValues] = useState<RatingInput | null>(null);
  const [konturYear, setKonturYear] = useState<number | null>(null);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
    getValues,
    setValue,
  } = useForm<RatingInputValues & { quarter: string; year: string }>({
    mode: 'onBlur',
    defaultValues,
  });

  const { mutateAsync } = useUpdateRatingMutation(inn, parseInt(id, 10), {
    onSuccess: () => {
      toast(t('RatingSuccessMessage'), 'success');
      setResult(null);
      resultRef.current?.scrollIntoView({ behavior: 'smooth' });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' && (query.queryKey as string).indexOf('ratings') >= 0
          );
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (values: RatingInputValues & { quarter: string; year: string }) => {
      const input = getRatingInput(values);
      return await mutateAsync(input);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const { mutateAsync: mutateAsyncCalculate } = useCalculateRatingMutation(inn, {
    onSuccess: (result: RatingCalculationViewModel) => {
      toast(t('RatingSuccessMessage'), 'success');
      setResult(result);
      resultRef.current?.scrollIntoView({ behavior: 'smooth' });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onCalculateSubmit = useMemo(() => {
    const submit = async (values: RatingInputValues & { quarter: string; year: string }) => {
      const input = getRatingInput(values);
      return await mutateAsyncCalculate(input);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsyncCalculate]);

  const dayMonth = useWatch({ control, name: 'quarter' });
  const year = useWatch({ control, name: 'year' });

  const humanDayMonth =
    dayMonth === '01.01'
      ? '1 квартал'
      : dayMonth === '04.01'
      ? 'полугодие'
      : dayMonth === '07.01'
      ? '9 месяцев'
      : dayMonth === '10.01'
      ? 'год'
      : undefined;

  useEffect(() => setValue('fromDate', `${dayMonth}.${year}`), [dayMonth, year, setValue]);

  // from 2012 to current year + 2
  const yearsCount = dayjs().year() - 2012 + 3;
  const yearOptions = Array(yearsCount)
    .fill(0)
    .map((e, i) => {
      return (
        <MenuItem key={i} value={2012 + i}>
          {2012 + i}
        </MenuItem>
      );
    });

  const getRating = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const ratingCopy = useCallback(() => {
    if (konturValues) {
      reset({ ...getValues(), ...konturValues });
    }
  }, [konturValues, reset, getValues]);

  useEffect(() => {
    if (konturValues) {
      reset({ ...getValues(), clearIncome: konturValues.clearIncome });
    }
  }, [konturValues, reset, getValues]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className={classes.period}>
          <div className={classes.quarter}>
            <Select
              label={t('Quarter')}
              name="quarter"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: '',
                },
              }}
            >
              <MenuItem value="">{t('Not set')}</MenuItem>
              <MenuItem value="01.01">1 квартал</MenuItem>
              <MenuItem value="04.01">полугодие</MenuItem>
              <MenuItem value="07.01">9 месяцев</MenuItem>
              <MenuItem value="10.01">год</MenuItem>
            </Select>
          </div>
          <div className={classes.year}>
            <Select
              label={t('Year')}
              name="year"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: '',
                },
              }}
            >
              <MenuItem value="">{t('Not set')}</MenuItem>
              {yearOptions}
            </Select>
          </div>
          {!isConfirmed && (
            <>
              <Button variant="contained" onClick={getRating}>
                Получить данные
              </Button>
              {konturYear && (
                <div className={classes.konturYearData}>
                  {`Получены данные за ${konturYear} год`}
                </div>
              )}
            </>
          )}
        </div>
        <Paper square className={classes.root}>
          <div className={classes.financial}>
            <header className={classes.header}>
              <Grid container>
                <Grid item xs={12} className={classes.headerItem}>
                  Финансовые показатели (руб.)
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={clsx(classes.right, classes.headerItem, classes.copyContainer)}
                >
                  {konturYear && (
                    <div onClick={ratingCopy}>
                      <span>Контур</span>
                      <Button
                        variant="iconButton"
                        endIcon={<IconArrowCopy />}
                        className={classes.copyButton}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={6} className={clsx(classes.right, classes.headerItem)}>
                  {humanDayMonth} {year}
                </Grid>
              </Grid>
            </header>
            <div>
              <RatingFinancialLabels />
              <RatingFinancialFields
                inn={inn}
                editId={editId}
                setEdit={setEditId}
                control={control}
                setValue={setValue}
                konturValues={konturValues}
              />
            </div>
          </div>
          <div className={classes.business}>
            <header className={classes.header}>Бизнес-параметры</header>
            <div>
              <RatingBusinessLabels />
              <RatingBusinessFields
                inn={inn}
                editId={editId}
                setEdit={setEditId}
                control={control}
                setValue={setValue}
              />
            </div>
          </div>
          <div className={classes.optional}>
            <header className={classes.header}>Опционально</header>
            <div>
              <RatingOptionalLabels />
              <RatingOptionalFields
                inn={inn}
                editId={editId}
                setEdit={setEditId}
                control={control}
              />
            </div>
          </div>
        </Paper>
        {(output || result?.output) && (
          <Paper square className={classes.output} ref={resultRef}>
            <RatingOutput output={result?.output ? result?.output : output} />
          </Paper>
        )}
        {(context || result?.calculationContext) && (
          <Paper square className={classes.outputFinancial}>
            <RatingFinancialParams
              input={result?.input ? result?.input : input}
              context={result?.calculationContext ? result?.calculationContext : context}
            />
          </Paper>
        )}
        {(input || result?.input) && (context || result?.calculationContext) && (
          <Paper square className={classes.outputBusiness}>
            <RatingBusinessParams
              input={result?.input ? result?.input : input}
              context={result?.calculationContext ? result?.calculationContext : context}
            />
          </Paper>
        )}
        <Portal container={document.body}>
          <Paper square className={classes.actions}>
            <Role role={ApplicationRoles.allExceptAssetManager}>
              <div className={classes.actionButton}>
                <Button
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting || isConfirmed}
                  onClick={onCalculateSubmit}
                >
                  {t('Calculate')}
                </Button>
              </div>
              <div className={classes.actionButton}>
                <Button
                  color="primary"
                  size="medium"
                  type="button"
                  variant="outlined"
                  disabled={isSubmitting || isConfirmed}
                  onClick={onSubmit}
                >
                  {t('Save')}
                </Button>
              </div>
            </Role>
          </Paper>
        </Portal>
      </form>
      <ModalForm open={open} onClose={onClose} width={500}>
        <RatingYearsModal
          inn={inn}
          setKonturValues={setKonturValues}
          setKonturYear={setKonturYear}
          onClose={onClose}
        />
      </ModalForm>
    </>
  );
};
